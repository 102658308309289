import { createSlice } from "@reduxjs/toolkit"
import TokenProvider from "../utils/token-provider"

declare const Kakao: any
declare const window: any

export interface AuthInitialState {
	// accessToken?: string
	// refreshToken?: string
	// id?: number
	// tokenType?:string
	// signUp?: boolean
	token? : {
		accessToken : string;
		refreshToken : string;
		id : string;
	}| null
	device_id?: any
}

const initialState: AuthInitialState = {
	// accessToken: "",
	// refreshToken:"",
	// id: 0,
	// tokenType:"jwt",
	// signUp: false,
	token: TokenProvider.getToken(),
	device_id: null,
}

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken(state, action) {
			TokenProvider.save({
				accessToken : action.payload.accessToken,
				refreshToken : action.payload.refreshToken,
				id : action.payload.id
			})
			// state.user = TokenProvider.getToken()
			state.token = action.payload
		},
		clearToken(state) {
			TokenProvider.clear()
			state.token = null
		},
		// setDeviceId(state, action) {
		// 	state.device_group = action.payload
		// },
	},
})

export const authReducer = authSlice.reducer
export const authActions = authSlice.actions
