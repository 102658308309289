import React, { ButtonHTMLAttributes } from 'react'
import { Link } from "react-router-dom"
import styled, { css } from 'styled-components'
import MainMark from '@/assets/images/main/main_mark.png'
//import BoardBannerImg from '@/assets/images/banner/boardBanner.jpeg'
import BoardBannerImg from '@/assets/images/banner/boardBanner.jpeg'
import {BannerWrap} from './style'

export const BoardBanner = () => {

    return (
        <>
            <BannerWrap className="defaultBanner BoardBanner">
                <div className="defaultBanner--cnt">
                    <div className="defaultBanner--cnt--txt">
                        <h3>동물도 사람과 같습니다</h3>
                        {/*<p></p>*/}
                    </div>
                    <img className="markImg" src={MainMark} alt=""/>
                </div>
                <img
                    className="banner--bg"    
                    src={BoardBannerImg}
                    alt=""
                />
            </BannerWrap>
        </>
    )
}

