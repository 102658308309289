import Styled from 'styled-components';

//리스트
export const ContentWrap = Styled.div`
    section{
        padding:100px 0;
        @media (max-width: 768px){
            padding:60px 0;
        }
    }
    background:#FCFDFD;
`

export const ContentSearch = Styled.div`
    width:90%;
    max-width:750px;
    margin-right:auto;
    margin-left:auto;
    padding:100px 0 50px;
    .ContentCnt{
        &--search{
            width:100%;
            //height:64px;
            height:48px;
            display:flex;
            align-items:stretch;
            justify-content:center;
            border-radius:32px;
            //border:1px solid #cecfcf;
            box-shadow:0 5px 10px rgba(0,0,0,0.06);
            border:1px solid rgba(0,0,0,0.15);
            overflow:hidden;
            @media (max-width: 768px){
                height:50px;
            }
            &--input{
                width:calc(100% - 50px);
                text-indent:34px;
                font-size:18px;
                color:#787e8b;
                @media (max-width: 768px){
                    // width:calc(100% - 60px);
                }
            }
            &--btn{
                width:50px;
                background:#29a36c;
                border-top-right-radius:25px;
                border-bottom-right-radius:25px;
                cursor:pointer;
                img{
                    transform:translateX(-3px);
                }
                @media (max-width: 768px){
                    // width:60px;
                }
            }
        }
        &--recommend{
            display:flex;
            align-items:flex-start;
            justify-content:center;
            margin-top:27px;
            gap:25px;
            @media (max-width: 768px){
                justify-content:flex-start;
                flex-wrap:wrap;
                gap:15px;
            }
            h4{
                font-size:18px;
                font-weight:900;
                color:#787e8b;
                letter-spacing:-0.1px;
                white-space:nowrap;
                margin-top:10px;
            }
            &--list{
                display:flex;
                align-items:center;
                justify-content:flex-start;
                flex-wrap:wrap;
                gap:20px;
                @media (max-width: 768px){
                    gap:10px;
                }
            }
        }
    }
`

export const ContentCnt = Styled.div`
    width:90%;
    max-width:750px;
    margin-right:auto;
    margin-left:auto;
    padding-bottom:120px;
    .ContentCnt{
        &--caption{
            font-size:16px;
            margin:0 0 15px;
        }
        &--list{
            display:flex;
            align-items:flex-start;
            justify-content:flex-start;
            flex-wrap:wrap;
            gap:40px 30px ;
            @media (max-width: 768px){
                gap:0;
            }
            li{
                width:360px;
                @media (max-width: 1240px){
                    width:calc(50% - 15px);
                }
                @media (max-width: 768px){
                    width:100%;
                    &:not(:last-child)::after{
                        content:'';
                        width:100%;
                        height:1px;
                        background:#D8D8D8;
                        display:block;
                        margin:40px 0;
                    }
                }
            }
        }
        &--item{
            display:block;
            width:100%;
            &--img{
                width:100%;
                // height:310px;
                aspect-ratio:1/1;
                border-radius:15px;
                overflow:hidden;
                @media (max-width: 1240px){}
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            &--cnt{
                margin-top:15px;
            }
            &--sub{
                padding:0 10px;
                height:30px;
                border-radius:15px;
                border:1px solid #d6d9e0;
                display:inline-flex;
                align-items:center;
                font-size:16px;
                font-weight:600;
                letter-spacing:-0.1px;
                color:#535968;
                @media (max-width: 768px){
                    font-size:16px;
                    font-weight:300;
                    height:28px;
                }
            }
            &--tit{
                width:100%;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                font-size:18px;
                font-weight:600;
                line-height:1;
                letter-spacing:-0.1px;
                color:#101219;
                margin:12px 0;
                @media (max-width: 768px){
                    margin:12px 0;
                }
            }
            &--txt{
                font-size:15px;
                font-weight:600;
                color:#727171;
                line-height:1.1;
                letter-spacing:-0.1px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                @media (max-width: 768px){
                    font-size:16px;
                    font-weight:300;
                }
            }
            &--date{
                font-size:15px;
                font-weight:600;
                color:#c4c8ce;
                line-height:1.35;
                letter-spacing:-0.1px;
                margin-top:5px;
                @media (max-width: 768px){
                    font-size:16px;
                    font-weight:300;
                    margin-top:10px;
                }
            }
        }
    }
`

//상세페이지
export const ContentViewWrap = Styled.div`
    width:100%;
    section{
        padding:100px 0;
        @media (max-width: 768px){
            padding:40px 0;
        }
    }
`

export const ContentViewCnt = Styled.div`
    width:90%;
    // max-width:720px;
    &.appView{
        width:calc(100% - 48px);
    }
    max-width:750px;
    margin-left:auto;
    margin-right:auto;
    padding:50px 0;
    .ContentView--tit{
        font-size:30px;
        // font-weight:900;
        font-weight:600;
        color:#000;
        word-break:break-all;
        // padding-left:35px;
        box-sizing:border-box;
        line-height:1.2;
        // margin-bottom:35px;
        margin-bottom:20px;
        position:relative;
        @media (max-width: 768px){
            font-weight:300;
            padding-left:20px;
            margin-bottom:20px;
            font-size:26px;
        }
        &::before{
            //content:'Q.';
            font-size:30px;
            font-weight:900;
            color:#000;
            position:absolute;
            left:0;
            top:0px;
            @media (max-width: 768px){
                font-weight:300;
                font-size:26px;
            }
        }
        &.appView--tit{
            @media (max-width: 768px){
                padding:0;
            }
        }
    }
    .ContentView--item{
        // margin-bottom:55px;
        margin-bottom:30px;
        &--tit{
            font-size:22px;
            font-weight:600;
            color:#000;
            word-break:break-all;
            position:relative;
            // padding-left:35px;
            box-sizing:border-box;
            line-height:1.2;
            // margin-bottom:20px;
            margin-bottom:4px;
            &::before{
                // content:'Q.';
                font-size:22px;
                font-weight:900;
                color:#000;
                position:absolute;
                left:0;
                top:0px;
                @media (max-width: 768px){
                    font-weight:300;
                }
            }
            @media (max-width: 768px){
                font-weight:300;
                padding-left:20px;
                margin-bottom:24px;
            }
        }
        &--cnt{
            font-size:16px;
            font-weight:600;
            color:#252525;
            line-height:1.4;
            word-break:break-all;
            white-space: pre-line;
            img, video, iframe {
                max-width:100%;
            }
            @media (max-width: 768px){
                font-weight:300;
                margin-left:20px;
            }
        }
        &--tag{
            margin-top:20px;
            display:flex;
            align-items:center;
            justify-content:flex-start;
            gap:24px;
            flex-wrap:wrap;
        }
        &--footer{
            display:flex;
            align-items:center;
            justify-content:flex-end;
            gap:5px;
        }
    }
    .appView--item{
        .ContentView--item{
            &--tit{ padding:0; }
            &--cnt{ padding:0; margin:0; }
        }
    }
`
