import React,{useState} from 'react'
import {Link, useLocation,useParams} from "react-router-dom"
import {DateFomat} from '@/utils/dateFomat'
import { useMCardView } from "@/config/api/mCardService"
import Pagination from 'rc-pagination'
import {PaginationWrap} from '@/assets/style/paginationStyle'
import { ContentViewBanner } from '@/containers/banner/index'
import { ContentViewWrap, ContentViewCnt} from '../styled'
import {Button} from '@/components/button/index'
import DummyImg from '@/assets/images/content/ContentViewDummy.png'
import styled from 'styled-components'
import IcApple from '@/assets/images/ic_apple.png'
import IcGoogle from '@/assets/images/ic_google.png'

const View = () => {

    const { id } = useParams();
    const { data } = useMCardView(id)
    const [current, setCurrent] = useState(1);
    const onChange = (page:number) => {
        setCurrent(page);
    };

    //console.log(data?.data.tags)

  return (
      <ContentViewWrap>
          <ContentViewBanner
            top={data?.data.top}
            summary={data?.data.summary}
            image={data?.data.image}
            date={data?.data.update_date?
                DateFomat(data?.data.update_date):
                DateFomat(data?.data.insert_date)
            }
          />
          <ContentViewCnt>
              <div className="ContentView--tit">
                  {data?.data.main_title}
              </div>
              {data?.data.contents?.map((item,index)=>(
                  <dl className="ContentView--item" key={index}>
                      <dt className="ContentView--item--tit" >
                          {item.sub_title}
                      </dt>
                      <dd className="ContentView--item--cnt">
                          {/*
                              {item.text?.split('<br/>').map( line => {
                                  return (<>{line} {`\n`}</>)
                              })}
                          */}
                          <div dangerouslySetInnerHTML={{__html:item.text}}/>
                          {item.videos&&item.videos.map((e,index)=>(
                              <video
                                  key={index}
                                  src={e}
                                  style={{ marginTop:'55px'}}
                              />
                          ))}
                          {item.images&&item.images.map((e,index)=>(
                              <img
                                  key={index}
                                  src={e}
                                  alt=""
                                  style={{ marginTop:'55px'}}
                              />
                          ))}
                      </dd>
                  </dl>
              ))}

                  {/* <div className="ContentView--item--tag">
                      {data?.data.tags?.filter((i)=>i!==null).map((item)=>(
                          <Button
                              key={item.id}
                              to={`/content/Content?keyword=${item.name}`}
                              color='lightGray'
                              height='35px'
                              radius='4px'
                          >
                              <span style={{ fontSize:'14px',fontWeight:'600',color:'#000' }}>#{item.name}</span>
                          </Button>
                      ))}
                  </div> */}
              {/*{data?.data.tags&&()}*/}

              <div className="ContentView--item--footer">
                  {/*
                  <Button to="/content/Content" color='lightGray' height='40px' radius='4px'>
                      <span style={{ fontSize:'14px',fontWeight:'600',color:'#000' }}>이전글</span>
                  </Button>
                  <Button to="/content/Content" color='lightGray' height='40px' radius='4px'>
                      <span style={{ fontSize:'14px',fontWeight:'600',color:'#000' }}>다음글</span>
                  </Button>
                  */}
                  {/* <Button to="/content/Content" color='primary' height='40px' radius='4px'>
                      <span style={{ fontSize:'14px',fontWeight:'600',color:'#fff' }}>목록으로</span>
                  </Button> */}
                  <AppLink href="https://8wmz.adj.st/navigation?scene=home&adj_t=19n2rjwi">
						<span style={{paddingTop: "6px"}}>앱으로 시작하기</span>
                        <img src={IcApple} alt=""/>
                        <img src={IcGoogle} alt=""/>
					</AppLink>
              </div>
          </ContentViewCnt>
          
      </ContentViewWrap>
  )
}

export default  View

export const AppLink = styled.a`
	cursor: pointer;
	border: none;
	outline: none;
	transition: all 0.2s;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	max-width: 100%;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 600;
	color: #000;
    &:hover {
        text-decoration: underline;
    }
`