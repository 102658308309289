import React,{useState} from 'react'
import { useBbsList, BbsListResponseItem } from "@/config/api/bbsService"
import {Link, useLocation} from "react-router-dom"
import Pagination from 'rc-pagination'
import cx from "classnames"
import {
    ReactChannelIO,
    useChannelIOApi,
    useChannelIOEvent
} from "react-channel-plugin";
import {DateFomat} from '@/utils/dateFomat'
import {Button} from '@/components/button'
import {BoardBanner} from '@/containers/banner/index'
import {PaginationWrap} from '@/assets/style/paginationStyle'
import { BoardListWrap, BoardListTab, BoardListCnt } from '../styled'
import NutrientItem from '@/assets/images/nutrientReport/NutrientItem.png'
//import IcArrowDown from '@/assets/images/ic-arrowDown.png'
import IcArrowDown from '@/assets/images/btnTop.png'
import IcCalendar from '@/assets/images/ic-calendar.png'
import IcView from '@/assets/images/ic-view.png'


const Notice = () => {

  const [current, setCurrent] = useState(1);
  const { data } =useBbsList({
      fieldType:'notice',
      page:current-1,
      limit:10,
  })
  const [boardState, setBoardState]=useState('notice')
  const onChange = (page:number) => {
        setCurrent(page);
  };
  const [ activeView, setctiveView] = useState(0)

  const dropDown = (e:number|any) => {
      if(activeView===e){
          setctiveView(0)
      }else{
          setctiveView(e)
      }
  }

    const showMessenger =() =>{ alert('준비 중입니다.')};
    /*
    const { showMessenger } = useChannelIOApi();
    useChannelIOEvent("onShowMessenger", () => {
        console.log("Messenger opened!");
    });
     */
  
  return (
      <BoardListWrap>
          <BoardBanner/>
          <BoardListCnt>
              <BoardListTab>
                  <Link to="/board/faq/Faq" className="">자주 묻는 질문</Link>
                  <Link to="/board/notice/Notice" className="active">공지사항</Link>
              </BoardListTab>
              <ul className="board--list">
                  {data?.data.content.map((item:BbsListResponseItem)=>(
                      <li key={item.id} className={cx("board--item",{
                            active:item.id===activeView
                      })}>
                          <div className="board--item--head">
                              <div className="board--item--head--tit">
                                  <button onClick={()=>dropDown(item.id)} className="board--list--tit">
                                      {item.title}
                                  </button>
                                  <div className="board--item--head--tit--sub">
                                      <p>
                                          <img src={IcCalendar} alt=""/>
                                          {item.update_date?
                                              DateFomat(item.update_date):
                                              DateFomat(item.insert_date)
                                          }
                                      </p>
                                      {/*
                                      <p>
                                          <img src={IcView} className="viewCount" alt=""/>
                                          {item.views}
                                      </p>
                                      */}
                                  </div>
                              </div>
                              <button  onClick={()=>dropDown(item.id)} className="board--item--head--btn">
                                  <img src={IcArrowDown} alt=""/>
                              </button>
                          </div>
                          <div className="board--item--body">
                              <div className="board--item--body--cnt"
                                   dangerouslySetInnerHTML={{
                                       __html : item.contents 
                                   }}
                              />

                          </div>
                      </li>
                  ))}
              </ul>
              <PaginationWrap>
                  <Pagination onChange={onChange} current={current} total={data?.data.totalElements} />
              </PaginationWrap>
              <div className="board--footer" >
                  <p>
                      찾으려는 답변이 없으신가요?
                      {/*원하시는 정보가 없거나 궁금한 부분이 해결되지 않았다면 1:1문의를 접수해주세요.*/}
                  </p>
                  <Button
                      to="/board/ask/Ask"
                      //onClick={showMessenger}
                      // to="mailto:contact@equal.pet"
                      color='black'
                      height='50px'
                      width='130px'
                  >
                      <span style={{ fontSize:'18px',fontWeight:'600',letterSpacing:'1px' }}>1:1문의</span>
                  </Button>
              </div>
          </BoardListCnt>
      </BoardListWrap>
  )
}

export default  Notice
