import Styled from 'styled-components';
import '@/assets/style/_utils.scss'


export const HeaderWrap = Styled.div`
    width:100%;
    position:sticky;
    top:0;
    left:0;
    z-index:10;
    .header{
        width:100%;
        height:80px;
        padding:0 40px;
        box-sizing:border-box;
        display:flex;
        align-items:center;
        justify-content:space-between;
        background:#fff;
        position:relative;
        box-shadow:0px 4px 4px rgba(0,0,0,0.04);
        position:initial;
        @media (max-width: 1024px){
            height:initial;
            padding:15px 5%;
            flex-wrap:wrap;
        }
        &--ham{
            display:none;
            padding:0;
            margin:0;
            @media (max-width: 1200px){
                display:flex;
                align-items:center;
                justify-content:center;
            }
            @media (max-width: 1024px){
                width:20px;
                height:30px;
                img{
                    width:100%;
                }
            }
        }
        &--logo{
            display:block;
            width:85px;
            img{
                width:100%;
            }
            @media (max-width: 1200px){
                position:absolute;
                left:50%;
                top:50%;
                transform:translate(-50%,-50%);
            }
            @media (max-width: 1024px){
                left:50%;
                top:15px;
                transform:translateX(-50%);
                width:50px;
            }
        }
        &--gnb{
            display:flex;
            align-items:center;
            justify-content:flex-start;
            width:100%;
            max-width:820px;
            padding:0 40px;
            box-sizing:border-box;
            gap:55px;
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%,-50%);
            margin-top:5px;
            @media (max-width: 1200px){
                display:none;
            }
            li{
                a{
                    //font-size:16.5px;
                    font-size:16px;
                    font-weight:600;
                    color:#333;
                    position:relative;
                    &.active{
                        color:#01B763;
                        // font-weight:900;
                        &::after{
                            content:'';
                            display:block;
                            width:100%;
                            height:1px;
                            background:#01B763;
                            position:absolute;
                            left:0;
                            bottom:-8px;
                        }
                    }
                }
            }
        }
        &--sub{
            display:flex;
            align-items:center;
            justify-contnet:flex-start;
             @media (max-width: 1024px){
                width:100%;
                // margin-top:15px;
            }
        }
        &--search{
            width:240px;
            height:44px;
            border-radius:22px;
            background:#f5f5f5;
            display:flex;
            align-items:center;
            justify-content:center;
            padding:0 12px;
            box-sizing:border-box;
            gap:10px;
            @media (max-width: 1024px){
                width:100%;
            }
            &--btn{
                width:20px;
                height:20px;
                display:flex;
                align-items:center;
                justify-content:center;
                cursor:pointer;
            }
            &--input{
                font-size:14px;
                font-weight:300;
                color:#838383;
                background:none;
                width:calc(100% - 30px);
            }
        }
        &--sub{
            &--btn{
                display:flex;
                align-items:center;
                justify-content:flex-end;
                margin-top:5px;
                @media (max-width: 1200px){
                    display:none;
                }
                a, button{
                    cursor:pointer;
                    margin-left:35px;
                    //font-size:16.5px;
                    font-size:16px;
                    font-weight:600;
                    color:#333;
                    letter-spacing:-0.5px;
                    display:flex;
                    align-items:center;
                    justify-content:flex-start;
                    gap:8px;
                    padding:0;
                }
            }
        }
    }
`

export const ContentWrap = Styled.div`
    min-height:80vh;
`

export const FooterWrap = Styled.div`
    position:relative;
    padding:25px 40px 70px;
    background:#fff;
    border-top:1px solid #cdcbca;
    box-sizing:border-box;
    @media (max-width: 768px){
        padding:20px 5% 50px;
    }
    .footer{
        display:flex;
        align-items:flex-start;
        justify-content:flex-start;
        gap:8px;
        &--logo{
            width:30px;
            img{
                width:100%;
            }
        }
        &--cnt{
            margin-top:5px;
            &--link{
                display:flex;
                align-items:flex-start;
                justify-content:flex-start;
                gap:10px;
                margin-bottom:1px;
                a{
                    font-size:14px;
                    font-weight:600;
                    color:#000;
                    letter-spacing:-0.1px;
                    line-height:1.25;
                }
            }
            &--infor{
                display:flex;
                align-items:flex-start;
                justify-content:flex-start;
                flex-wrap:wrap;
                &.footer--cnt--infor01{
                }
                &.footer--cnt--infor02{}
                li{
                    font-size:14px;
                    font-weight:600;
                    color:#000;
                    // letter-spacing:-0.1px;
                    line-height:1.25;
                    @media (max-width: 768px){
                        font-weight:300;
                        font-size:10px;
                    }
                    &:not(:last-child)::after{
                        content:'/';
                        margin:0 6px;
                    }
                }
            }
            &--caption{
                margin-top:12px;
                font-size:14px;
                font-weight:600;
                color:#000;
                // letter-spacing:-0.1px;
                line-height:1.4;
                @media (max-width: 768px){
                    font-weight:300;
                    font-size:10px;
                }
            }
        }
        &--icon{
            margin-left:auto;
            display:flex;
            align-items:center;
            justify-content:flex-end;
            gap:30px;
            margin-right:50px;
            a{
                width:30px;
                img{
                    width:100%;
                }
            }
        }
        @media (max-width: 768px){
            flex-wrap:wrap;
            &--logo{
                // width:100%;
                //margin-bottom:20px;
            }
            &--icon{
                justify-content:flex-start;
                margin-right:0;
                margin-left:0;
                gap:20px;
                a{
                    width:20px;
                }
            }
        }
    }
    .btnTop{
        z-index:10;
        position:fixed;
        right:60px;
        //bottom:60px;
        bottom:85px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        img{
            width:80px;
            display:block;
        }
        p{
            margin-top:8px;
            font-size:16px;
            font-weight:400;
            letter-spacing:-0.1px;
            color:#000;
        }
        @media (max-width: 1024px){
            right:5%;
            bottom:30px;
            
            img{
                width:50px;
            }
            p{
                font-size:11px;
            }
            
        }
    }
    .appDown{
        width:160px;
        position:fixed;
        z-index:10;
        border:1px solid #000;
        box-sizing:border-box;
        right:60px;
        top:560px;
        // top:50%;
        // transform:translateY(20%);
        // box-shadow:0 15px 30px rgba(0,0,0,0.4);
        z-index:2;
        &::after{
            content:'';
            width:100%;
            height:100%;
            display:block;
            position:absolute;;
            top:0;
            left:0px;
            z-index:-1;
            
            box-shadow:0 15px 30px rgba(0,0,0,0.2);
        }
            // border:1px solid rgba(0,0,0,0.15);
        @media (max-height: 870px){
            top:calc(50% + 0px);
        }
        @media (max-width: 1024px){
            width:100px;
            transform:initial;
            top:initial;
            bottom:120px;
            right:5%;
        }
        @media (max-width: 480px){
            width:80px;
            bottom:100px;
        }
        dt{
            width:100%;
            aspect-ratio:1/1;
            padding:5px;
            box-sizing:border-box;
            background:#fff;
            img{
                width:100%;
            }
        }
        dd{
            text-align:center;
            padding:8px 0;
            background:rgba(0,0,0,0.88);
            font-size:14px;
            color:#01b763;
            strong{
                font-size:14px;
                color:#01b763;
            }
            @media (max-width: 1024px){
                font-size:11px;
                strong{
                    font-size:11px;
                }
            }
            @media (max-width: 480px){
                font-size:10px;
                letter-spacing:-1px;
                strong{
                    font-size:10px;
                    letter-spacing:-1px;
                }
            }
        }
    }
`

export const MoNav = Styled.div`
    width:100%;
    height:100vh;
    position:fixed;
    left:0;
    top:0;
    z-index:20;
    background:#fff;
    .MoNav{
        &--head{
            background:#000;
            padding:15px 5%;
            box-sizing:border-box;
            display:flex;
            align-items:center;
            justify-content:space-between;
        }
        &--controller{
            display:flex;
            align-items:center;
            justify-content:flex-end;
        }
        &--logBtn{
            display:flex;
            align-items:center;
            justify-content:flex-start;
            &::before{
                content:'';
                width:1px;
                height:14px;
                display:block;
                background:#fff;
                margin-right:15px;
            }
            font-size:15px;
            line-height:2;
            color:#fff;
            letter-spacing:-0.1px;
        }
        &--cnt{
            .mt-30{
                margin-top:30px;
            }
        }
        &--gnb{
            padding:12px 5%;
            box-sizing:border-box;
            display:flex;
            flex-direction:column;
            align-items:flex-start;
            justify-content:flex-start;
            gap:12px;
            li{
                a, button{
                    -webkit-appearance:none;
                    -moz-appearance:none;
                    -o-appearance:none;
                    appearance:none;
                    padding:0;
                    margin:0;
                    font-size:18px;
                    font-weight:300;
                    line-height:1.45;
                    letter-spacing:-0.1px;
                    color:#101219;
                }
            }
        }
        &--myGnb{
            dt{
                display:flex;
                align-items:center;
                justify-content:space-between;
                padding:15px 3%;
                box-sizing:border-box;
                cursor:pointer;
                font-size:16px;
                color:#555;
                img{
                    transition-duration:0.5s;
                    &.active{
                        transform:rotate(180deg);
                    }
                }
            }
            dd{
                max-height:0;
                overflow:hidden;
                transition-duration:0.5s;
                &.active{
                    max-height:400px;
                }
            }
        }
    }
`
