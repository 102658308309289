import { BrowserRouter, BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
// layouts
import Body from '@/containers/layout/Body'
// misc pages
import { ErrorPage } from '@/pages/misc/ErrorPage'
import { NotFoundPage } from '@/pages/misc/NotFoundPage'
import AuthKakaoSuccessCallbackPage from '@/pages/login/oauth2/code/kakao'
import AuthNaverSuccessCallbackPage from '@/pages/login/oauth2/code/naver'
import AuthGoogleSuccessCallbackPage from '@/pages/login/oauth2/code/google'
import { Provider as ReduxProvider, useSelector } from "react-redux"
import React,{useEffect} from 'react';
import { MyageService } from './config/api/mypageService';
import {RootState, store } from "../src/store/store"
import TokenProvider from "./utils/token-provider";
import useUser from "@/hooks/useUser"
import PrivateRoute from "@/components/PrivateRoute"

//pages
import Main from '@/pages/main';
import Test from '@/pages/test';
import Company from '@/pages/company/Company';
import Business from '@/pages/business/Business';
import Content from '@/pages/content/Content';
import ContentApp from '@/pages/content/ContentApp';
import ContentView from '@/pages/content/view/View';
import ContentViewApp from '@/pages/content/view/ViewApp';
import NutrientReport from '@/pages/nutrientReport/NutrientReport';
import NutrientReportView from '@/pages/nutrientReport/view/View';
import Report from '@/pages/report/Report';
import ReportView from '@/pages/board/report/view/View';
import Notice from '@/pages/board/notice/Notice';
import NoticeView from '@/pages/board/notice/view/View';
import Faq from '@/pages/board/faq/Faq';
import FaqView from '@/pages/board/faq/view/View';
import Ask from '@/pages/board/ask/Ask';
import LoginView from '@/pages/user/login/Login';
import Profile from '@/pages/user/mypage/Profile';
import Interview from '@/pages/user/mypage/Interview';
import Payment from '@/pages/user/mypage/Payment';
import Delivery from '@/pages/user/mypage/Delivery';
import { useUserInfo } from "@/hooks/useUserInfo";

import SplashRedirect from "@/components/SplashRedirect";
import DownloadRedirect from "@/components/DownloadRedirect";

const RoutesGroup = () => {

    const {signOut,isLoggedIn}=useUser()

    return (
        <Router>
            <Routes>
				<Route path="/" element={<SplashRedirect redirectURL={process.env.REACT_APP_REDIRECT_URL} />} />
                <Route path="/download" element={<DownloadRedirect />} />
                <Route element={<Body />}>
                      {/* <Route path="/" element={<Main />} /> */}
                      {/* <Route path="/test" element={<Test />} /> */}
                      {/* <Route path="/login/oauth2/code/kakao" element={<AuthKakaoSuccessCallbackPage />} />
                      <Route path="/login/oauth2/code/naver" element={<AuthNaverSuccessCallbackPage />} />
                      <Route path="/login/oauth2/code/google" element={<AuthGoogleSuccessCallbackPage />} /> */}
                      {/* <Route path="/company/Company" element={<Company />} />
                      <Route path="/business/Business" element={<Business />} />
                      <Route path="/content/Content" element={<Content />} /> */}
                      <Route path="/content/View/:id" element={<ContentView />} />
                      {/* <Route path="/nutrientReport/NutrientReport" element={<NutrientReport />} />
                      <Route path="/nutrientReport/View/:id" element={<NutrientReportView />} /> */}
                      {/* <Route path="/board/report/Report" element={<Report />} />
                      <Route path="/board/report/View/:id" element={<ReportView />} /> */}
                      <Route path="/board/notice/Notice" element={<Notice />} />
                      <Route path="/board/faq/Faq" element={<Faq />} />
                      {/* <Route path="/board/faq/View/:id" element={<FaqView />} /> */}
                      <Route path="/board/ask/Ask" element={<Ask />} />
                      {/* <Route path="/user/login/Login" element={<LoginView />} /> */}
                      {/* <Route element={ <PrivateRoute />}>
                          <Route path="/user/mypage/profile" element={<Profile />} />
                          <Route path="/user/mypage/interview" element={<Interview />} />
                          <Route path="/user/mypage/payment" element={<Payment />} />
                          <Route path="/user/mypage/delivery" element={<Delivery />} />
                      </Route> */}
                </Route>

                {/* <Route path="/content/ContentApp" element={<ContentApp />} /> */}
                <Route path="/content/ViewApp/:id" element={<ContentViewApp />} />
            </Routes>
        </Router>
  )
}

export default RoutesGroup
