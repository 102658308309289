import React,{useState} from 'react'
import qs from "query-string"
//import { useBbsList, BbsListResponseItem } from "@/config/api/bbsService"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {useForm} from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { useTerm } from "@/config/api/loginService"
//import { useAsk , bbsService} from "@/config/api/bbsService"
import bbsService,{askProps} from "@/config/api/bbsService"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { InputText, InputFile, InputCheckbox, Textarea } from '@/components/input/index'
import Pagination from 'rc-pagination'
import cx from "classnames"
import dayjs from 'dayjs'
import {DateFomat} from '@/utils/dateFomat'
import {Button} from '@/components/button'
import {Modal} from '@/components/modal/Modal'
import {PaginationWrap} from '@/assets/style/paginationStyle'
import { BoardListWrap, BoardListCnt, AskCnt } from '../styled'
import NutrientItem from '@/assets/images/nutrientReport/NutrientItem.png'
import IcArrowDown from '@/assets/images/ic-arrowDown.png'
import IcCalendar from '@/assets/images/ic-calendar.png'
import IcView from '@/assets/images/ic-view.png'

const scheme = yup.object({
    company_name: yup.string().required('상호명 또는 이름을 입력해주세요'),
    email: yup.string()
        .matches(/^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,'올바른 이메일 형식을 입력해주세요')
        .required('이메일을 입력해주세요.'),
    phone: yup.string(),
    content: yup.string().required('내용을 입력해주세요'),
    //.matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@!%*#?&])[A-Za-z\d@!%*#?&]{8,}$/,'8글자 이상 염문자, 숫자, 특수문자를 조합해서 입력하세요')
})

const Ask = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState
        //formState: { errors },
    } = useForm<askProps>({
        mode : 'onChange',
        resolver: yupResolver(scheme),
    });

    const { errors, isValid, submitCount } = formState

    const { data:termsData }  =useTerm({
        type:"service",
        date:dayjs(new Date()).format('YYYY-MM-DD')
    })

    const [stateChk, setStateChk]=useState(false)

    const handleChk = (e) =>{
        setStateChk(!stateChk)
    }

    const onSubmit = handleSubmit(
        async (values) => {
            //console.log("submit")
            console.log(values)

            if(stateChk){
                try {

                    console.log(values)
                    alert('문의 사항이 접수되었습니다.')
                    /*
                    if (!values.reply_info) {
                        alert("답변알림 정보를 입력해주세요.")
                        return
                    }
                    */
                    const payload: askProps = {
                        company_name: values.company_name,
                        email: values.email,
                        phone: values.phone,
                        content: values.content
                    }
                    const response = await bbsService.ask(payload)
                    //const savedId = response.data.data.company_name
                    //queryClient.refetchQueries(["useOtoList"])
                    //router.push(`/toad/csCenter/counsel/list/${savedId}`)
                    navigate(-1)
                } catch (errors) {
                    console.log(errors)
                    alert("error")
                }
            }else{
                alert('개인정보 수집에 동의해주세요')

            }
            // console.log("values", response.data)
        }
    )

  const [ termsPop, setTermsPop ] = useState(false)
  const handlePop = () =>{
        setTermsPop(true)
  }
  const closePop = () =>{
        setTermsPop(false)
  }

  return (
      <BoardListWrap>
          <BoardListCnt>
              <div className="board--tit">1:1 문의</div>
              <form onSubmit={onSubmit}>
                  <AskCnt>
                      <table className="AskTable">
                          <tr>
                              <th>성함*</th>
                              <td>
                                  <input type="text" {...register("company_name",{required: true,})}/>
                                  <div className="msg">{errors?.company_name?.message}</div>
                                  {/*<InputText  />*/}
                              </td>
                          </tr>
                          <tr>
                              <th>이메일*</th>
                              <td>
                                  <input type="text" {...register("email",{required: true,})}/>
                                  <div className="msg">{errors?.email?.message}</div>
                                  {/*<InputText  />*/}
                              </td>
                          </tr>
                          <tr>
                              <th>휴대폰</th>
                              <td>
                                  <input type="text" {...register("phone",{required: false,})}/>
                                  {/*<InputText  />*/}
                              </td>
                          </tr>
                          <tr>
                              <th>문의내용*</th>
                              <td>
                                  <textarea {...register("content",{required: true,})} />
                                  <div className="msg">{errors?.content?.message}</div>
                              </td>
                          </tr>
                          {/*
                              <tr>
                                  <th>파일접수</th>
                                  <td>
                                      <InputFile id="file0" />
                                      <p>파일첨부는 JPG, JPEG, PNG, PDF, PPT, PPTX, DOCX, DOC 만 가능합니다.</p>
                                  </td>
                              </tr>
                          */}
                      </table>
                      <div className="AskTable--caption">
                          <input type="checkbox" onChange={handleChk} id="askChk"/>
                          <label htmlFor="askChk">
                              <span></span>
                              개인정보 수집 및 이용에 동의합니다.
                          </label>

                          {/*<InputCheckbox id='chk1' cnt='개인정보 수집 및 이용에 동의합니다.' />*/}
                          {/*<button onClick={handlePop} type="button">내용보기</button>*/}
                          <a href={process.env.REACT_APP_TERMS_USE} target="_blank">내용보기</a>
                      </div>
                      <p style={{textAlign:'left', marginTop:'10px', fontSize:'14px'}}>
                          답변은 입력하신 이메일로 전달됩니다. (발신자 : contact@equal.pet)
                      </p>

                      <div className="AskTable--btn">

                          <button type="submit">
                              문의접수
                          </button>
                      </div>
                  </AskCnt>
              </form>
          </BoardListCnt>

          {termsPop&&(
              <Modal
                  tit="개인정보 수집 및 이용 동의"
                  onClose={closePop}
                  onOk={closePop}
                  cnt={
                      <div dangerouslySetInnerHTML={{
                          __html : termsData?.data.data ??''
                      }}>

                      </div>
                  }
              />
          )}
      </BoardListWrap>
  )
}

export default  Ask
