import React,{useEffect} from 'react';
import { QueryClientProvider } from "react-query"
import { Provider as ReduxProvider, useSelector } from "react-redux"
import { ReactChannelIO } from "react-channel-plugin";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
//import { useMediaQuery } from "react-responsive";
import '@/assets/style/style.css'
import { queryClient } from '@/utils/query-client'
import TokenProvider  from '@/utils/token-provider'
import RoutesGroup from '@/routes'
//import PrivateRoutesGroup from '@/privateRoutes'
import {RootState, store } from "./store/store"
import { MyageService } from './config/api/mypageService';

/**
 * dayjs 설정
 */
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear'; // 윤년 판단 플러그인
import 'dayjs/locale/ko'; // 한국어 가져오기

dayjs.extend(isLeapYear); // 플러그인 등록
dayjs.locale('ko'); // 언어 등록


declare global {
    interface Window {
        Kakao: any;
        naver: any;
    }
}

function App() {
    // const isPc: boolean = useMediaQuery({
    //     query: "(min-width:1024px)",
    // });
    // const isTa: boolean = useMediaQuery({
    //     query: "(min-width:768px) and (max-width:1023px)",
    // });
    // const isMo: boolean = useMediaQuery({
    //     query: "(max-width:767px)",
    // });

    // console.log('////////',TokenProvider.getToken())

    const CHANNEL_ID_PLUGIN_KEY = "831b3e1f-11a3-462a-b139-d3e106ae8c43";
    const GOOGLE_CLIENT_ID=`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`


  return (
      <>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <QueryClientProvider client={queryClient}>
                  <ReduxProvider store={store}>

                       {/*<ReactChannelIO pluginKey={CHANNEL_ID_PLUGIN_KEY} language="en" autoBoot>*/}
                        <RoutesGroup />
                       {/*</ReactChannelIO>*/}

                  </ReduxProvider>
              </QueryClientProvider>
          </GoogleOAuthProvider>
      </>
  );
}

export default App;
