import { useEffect } from "react"
import { useNavigate } from "react-router"

export default function DownloadRedirect() {
	const navigate = useNavigate()

	useEffect(() => {
		const userAgent = navigator.userAgent

		if (/android/i.test(userAgent) && process.env.REACT_APP_REDIRECT_ANDROID) {
			window.location.href = process.env.REACT_APP_REDIRECT_ANDROID
		} else if (
			/iPad|iPhone|iPod/.test(userAgent) &&
			process.env.REACT_APP_REDIRECT_IOS
		) {
			window.location.href = process.env.REACT_APP_REDIRECT_IOS
		} else {
			navigate("/")
		}
	}, [navigate])

	return <></>
}
