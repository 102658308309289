import React, {Component, ErrorInfo, ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import {ContentWrap} from './styled'
interface Props {
    children?: ReactNode;
}

const LoadingSpinner = Styled.div`
`


interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <h1>Sorry.. there was an error</h1>;
        }

        return this.props.children;
    }
}

const Body = () =>{

    return (
        <>
            <Header/>
            <ContentWrap>
                <ErrorBoundary>
                    <Suspense  fallback={
                        <div style={{
                            width:'100%',
                            height:'100vh',
                            position:'fixed',
                            zIndex:'99',
                            background:'black',
                            color:'#fff'
                        }}>

                        </div>
                    }>
                        <Outlet />
                    </Suspense>
                </ErrorBoundary>
            </ContentWrap>
            {/* <Footer/> */}
        </>
    )
}
export default Body;