import { httpClient } from "./axios"
import { ApiResponse } from "./common.model"
import { useQuery, UseQueryOptions } from "react-query"

export  interface  MCardServiceRequest{
    page?:number
    limit?:number
    search?:string
    user_id?:number
    pet_id?:number
    tag_id?:string
    disp_yn?:string
    type?:string
    totalElements?:number
}

export interface MCardServicListItem{
    id: number,
    top?: string,
    summary?: string,
    main_title?: string,
    image?: string,
    type?: "article"|"faq"|string,
    sort?: number,
    pet_yn?: string,
    bookmark?: boolean,
    bookmark_id?: number,
    contents?: {
        sub_title?: string,
        text: string,
        // text?: React.ReactNode,
        videos?: string[],
        images?: string[]
    }[],
    tag?: any,
    insert_user?: number,
    update_user?: number,
    insert_date?: string,
    update_date?: string,
    tags?:{
        id?:number,
        name?:string,
        health_yn?:string,
        medical_yn?:string,
    }[]
}

export interface MCardServiceView{
    id: number,
    top?: string,
    summary?: string,
    main_title?: string,
    image?: string,
    type?: "article"|"faq"|string,
    sort?: number,
    pet_yn?: string,
    bookmark?: boolean,
    bookmark_id?: number,
    contents?: {
        sub_title?: string,
        text: string,
        // text?: HTMLElement,
        videos?: string[],
        images?: string[]
    }[],
    tag?: any,
    insert_user?: number,
    update_user?: number,
    insert_date?: string,
    update_date?: string,
    tags?:{
        id?:number,
        name?:string,
        health_yn?:string,
        medical_yn?:string,
    }[]
}

export interface MCardServiceResponse{
    content:MCardServicListItem[]
    pageable?: {
        sort?:{
            empty?:boolean,
            sorted?:boolean,
            unsorted?:boolean,
        },
        offset?:number,
        pageNumber?:number,
        pageSize?:number,
        paged?:boolean,
        unpaged?:boolean,
    },
    totalElements?:number,
    totalPages?:number,
    size?:number,
}


class MCardService {
    async list(params:MCardServiceRequest) {
        return httpClient.get<ApiResponse<MCardServiceResponse>>("/mcard-service/v1/mcard", {
            params: {
                // page: params.page,
                // limit: 10
                page: params.page,
                limit: params.limit,
                search:params.search,
                totalElements:params.totalElements,
                type:'contents_web'
            }
        })

        //console.log('2222',params.page)
    }

    async view(id: string | number) {
        return httpClient.get<ApiResponse<MCardServiceView>>(`/mcard-service/v1/mcard/${id}`, {
        // return httpClient.get<ApiResponse<MCardServiceView>>("/mcard-service/v1/mcard", {
            params: {
                id,
            },
        })
    }
}

const mCardService = new MCardService()

export default  mCardService

export function useMCardList(params:MCardServiceRequest){
    const data = useQuery(
        ["contentList",params],
        async ()=>{
            const { data } = await mCardService.list({...params})

            return data
        },
        { }
    )

    return data
}

export function useMCardView(params:string|any){
    const data = useQuery(
        ["contentView",params],
        async ()=>{
            const { data } = await mCardService.view(params)

            return data
        },
        {

        }
    )

    return data
}