import { configureStore } from "@reduxjs/toolkit"
import logger from "redux-logger"
import { authReducer } from "./auth-slice"

export const store = configureStore({
	reducer: {
		auth: authReducer,
	},
	middleware: (getDefaultMiddleware) => {
		let middleware: any = getDefaultMiddleware({
			serializableCheck: false,
		})

		// if (process.env.NODE_ENV !== "production") {
		// 	middleware = middleware.concat(logger)
		// }

		return middleware
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
