import { useQuery,useMutation, UseQueryOptions } from "react-query"
import qs from "query-string"
import { httpClient } from "./axios"
import { ApiResponse } from "./common.model"

export interface SnsCheckRequest {
	type?: "KAKAO"| "NAVER" | "APPLE" | "GOOGLE"
	id?: string
}

export interface LoginRequest {

	//uid
	id:string

	// 소셜 타입값 (GOOGLE,APPLE,NAVER,KAKAO)
	type: "KAKAO"| "NAVER" | "APPLE" | "GOOGLE"

	// 회원 이름
	name?: string

	// 회원 이메일
	email?: string

	device_num?: string

	// 디바이스 UUID
	device_unique?: string

	// 디바이스 종류 (A:안드로이드, I:ios)
	device_group?: string

	// 이용약관 동의 여부 (Y/N)
	service_agree: "Y"|"N"

	// 개인정보 처리방침 동의 여부 (Y/N)
	privacy_agree: "Y"|"N"

	// 마케팅 활용 및 알림 동의 여부 (Y/N)
	marketing_agree?: "Y"|"N"

	// 자동 로그인 (Optional)
	//auto_login?: boolean
	// 푸시 토큰 값
	//push_token?: string
	// sns 토큰 값 (Optional)
	//sns_token?: string
}

export interface LoginPayload {
	accessToken:string,
	refreshToken:string,
	id:number,
	tokenType:string,
	signUp:boolean
}

export interface TermsRequest {
	type:"service"|"privacy"|"marketing"|"cert"|"age_14"|"commerce"
	date:string
}

export interface TermsPayload{
	id?:number
	type?:"service"|"privacy"|"marketing"|"cert"|"age_14"|"commerce"
	data?:string
	date?:string
	insert_date?:string
	update_date?:string
	insert_user?:number
	update_user?:number
}


class LoginService {
	/**
	 * 로그인
	 */
	async login(payload:LoginRequest) {
		// return httpClient.post<ApiResponse<LoginRequest>>("/user-service/v1/auth/social", {
		// 	headers: {
		// 		"Content-Type": "application/x-www-form-urlencoded",
		// 	},
		// 	payload:{
		// 		email: "abc2@gmail.com",
		// 		id: "555586089164778833095",
		// 		type: "KAKAO",
		// 		service_agree: "Y",
		// 		privacy_agree: "Y",
		// 		device_unique: "deviceuniquenumber0002",
		// 		device_group: "A",
		// 		device_num: "fcm_token_000002",
		// 	}
		// })

		return httpClient.post("/user-service/v1/auth/social", qs.stringify(payload), {
			headers: {
				"Content-Type": "application/json",
			},
		})
	}

	async term(params:TermsRequest){
		return httpClient.get<ApiResponse<TermsPayload>>("sign-service/v1/terms",{
			params:{
				type:params.type,
				date:params.date
			},
		})
	}
	/**
	 * 비밀번호 재설정 - 새로운 비밀번호
	 * @param {object} payload
	 * @param {!string} payload.email 회원 이메일
	 * @param {!string} payload.password 회원 비밀번호
	 *
	 */
}

const loginService = new LoginService()

export default loginService

/**
 * @typedef {object} LoginParam
 * @property {?boolean} auto_login 자동 로그인 (Optional)
 * @property {!string} device_unique 디바이스 UUID
 * @property {!string} email 회원 이메일
 * @property {!string} payload.login_type 로그인 타입 (email: 이메일, sns: 소셜)
 * @property {!string} password 회원 비밀번호
 * @property {!string} push_token 푸시 토큰 값
 * @property {?string} payload.sns_token sns 토큰 값 (Optional)
 * @property {!string} user_os 회원 OS
 *
 * @callback Login
 * @param {LoginParam} param
 * @returns {Promise}
 */

/**
 * 로그인
 * @return {Login}
 */
export function useLogin() {
	const { mutateAsync } = useMutation(loginService.login)
	return mutateAsync
}

export function useTerm(params:TermsRequest){
	const data =useQuery(
		["contentList",params],
		async ()=>{
			const { data } = await loginService.term({...params})

			return data
		},{

		}
	)
	
	return data
}

/**
 * @typedef {object} SNSCheckParam
 * @property {!string} join_ch 가입 채널 (K: 카카오, N: 네이버, F: 페이스북, G: 구글, A: 애플)
 * @property {!string} sns_token sns 토큰 값
 *
 * @callback SNSCheck
 * @param {SNSCheckParam} param
 * @returns {Promise}
 */

/**
 * @typedef {object} EmailCheckParam
 * @property {!string} payload.email 회원 이메일
 *
 * @callback EmailCheck
 * @param {EmailCheckParam} param
 * @returns {Promise}
 */


/**
 * @typedef {object} PasswordResetParam
 * @property {!string} payload.email 회원 이메일
 * @property {!string} payload.password 회원 비밀번호
 *
 * @callback PasswordReset
 * @param {PasswordResetParam} param
 * @returns {Promise}
 */


