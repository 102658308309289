import React,{useState} from 'react'
import {Link, useLocation,useParams} from "react-router-dom"
import {DateFomat} from '@/utils/dateFomat'
import { useMCardView } from "@/config/api/mCardService"
import Pagination from 'rc-pagination'
import {PaginationWrap} from '@/assets/style/paginationStyle'
import { ContentViewBanner } from '@/containers/banner/index'
import { ContentViewWrap, ContentViewCnt} from '../styled'
import {Button} from '@/components/button/index'
import DummyImg from '@/assets/images/content/ContentViewDummy.png'

const ViewApp = () => {

    const { id } = useParams();
    const { data } = useMCardView(id)
    const [current, setCurrent] = useState(1);
    const onChange = (page:number) => {
        setCurrent(page);
    };

    console.log(data)

  return (
      <ContentViewWrap>
          <ContentViewCnt className="appView">
              <div className="ContentView--tit appView--tit">
                  {data?.data.main_title}
              </div>
              {data?.data.contents?.map((item,index)=>(
                  <dl className="ContentView--item appView--item" key={index}>
                      <dt className="ContentView--item--tit" >
                          {item.sub_title}
                      </dt>
                      <dd className="ContentView--item--cnt">
                          {/*
                              {item.text?.split('<br/>').map( line => {
                                  return (<>{line} {`\n`}</>)
                              })}
                          */}
                          <div dangerouslySetInnerHTML={{__html:item.text}}/>
                          {item.videos&&item.videos.map((e,index)=>(
                              <video
                                  key={index}
                                  src={e}
                                  style={{ marginTop:'55px'}}
                              />
                          ))}
                          {item.images&&item.images.map((e,index)=>(
                              <img
                                  key={index}
                                  src={e}
                                  alt=""
                                  style={{ marginTop:'55px'}}
                              />
                          ))}
                      </dd>
                  </dl>
              ))}

          </ContentViewCnt>
          
      </ContentViewWrap>
  )
}

export default  ViewApp
