import {useLogin} from "@/config/api/loginService"
import jwt_decode , { JwtPayload } from "jwt-decode";
//import jwt from "jsonwebtoken"

type tokenProps = JwtPayload & {
	sub:string
	roles:string[]
	exp:number
}

class TokenProvider {
	static tokenName = "user_token"

	// static decode(jwtToken) {
	//	 return jwt.decode(jwtToken)
	// }

	static getToken() {
		const token = localStorage.getItem(this.tokenName);
		return token ? JSON.parse(token) : null
	}

	static save(jwtToken) {
		localStorage.setItem(this.tokenName, JSON.stringify(jwtToken))
	}

	static clear() {
		localStorage.removeItem(this.tokenName)
	}

	static isNotExpires(jwtToken:any) {
		try {
			if (TokenProvider.getToken()?.accessToken === undefined) {
				return false
			}

			const accessT:tokenProps = jwt_decode(TokenProvider.getToken().accessToken)

			if (accessT && accessT.exp) {
				const exp = new Date(accessT.exp * 1000).getTime()
				//console.log(exp,'//////',exp - new Date().getTime())

				//return exp - new Date().getTime() > 3590000

				return exp > new Date().getTime()
			}
			
			return false
		} catch (e) {
			console.error(e)
			return true
		}
	}

	/**
	 * 토큰을 검사합니다.
	 * @param jwtToken - 이 값이 있으면, 이 값을 검사하고, 없다면, storage 에서 탐색합니다.
	 */
	static verify(jwtToken: string|any): boolean {
		try {
			const token = jwtToken || TokenProvider.getToken()

			if (!token) return false

			//const decode = jwt.decode(token)

			//if (!decode) return false

			const isNotExpires = TokenProvider.isNotExpires(token)

			return isNotExpires
		} catch (e) {
			return false
		}
	}
}

export default TokenProvider
