import React, { ButtonHTMLAttributes } from 'react'
import { Link } from "react-router-dom"
import styled, { css } from 'styled-components'
// import ContentViewBannerImg from '@/assets/images/content/ContentViewBanner.png'
import ContentViewBannerImg from '@/assets/images/content/ContentBanner.png'
import {BannerWrap} from './style'

export interface CntBannerProps{
    top?:string
    summary?:string
    image?:string
    date?:string
}

export const ContentViewBanner = ({top, summary, image, date}:CntBannerProps) => {

    return (
        <>
            <BannerWrap className="viewBanner" >
                <div className="viewBanner--txt">
                    <h2>{top}</h2>
                    <h5>{summary}</h5>
                    <p>{date}</p>
                </div>
                <img
                    className="banner--bg"    
                    src={image?image:ContentViewBannerImg}
                    alt=""
                />
            </BannerWrap>
        </>
    )
}

