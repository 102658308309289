import React,{useState, useEffect} from 'react';
import cx from 'classnames';
import TokenProvider  from '@/utils/token-provider'
import useUser from "@/hooks/useUser"
//import { authActions,authReducer, AuthInitialState } from "@/store/auth-slice"
import {Link, useNavigate, useLocation} from "react-router-dom"
import ScrollToTop from '@/hooks/ScrollToTop'
import { useSelector } from "react-redux"
import {store} from "@/store/store"
import {HeaderWrap, MoNav} from './styled'
import Logo from '@/assets/images/logo_header.png'
import LogoWhite from '@/assets/images/logo_white.png'
import IcUser from '@/assets/images/ic-user.png'
import IcHam from '@/assets/images/ic-ham.png'
import IcClose from '@/assets/images/ic-closeWhite.png'
import IcArrowDown from '@/assets/images/ic-arrowDown.png'
import IcQna from '@/assets/images/ic-qna.png'
import IcLogin from '@/assets/images/ic-login.png'
import IcLogout from '@/assets/images/ic-logout.png'
import SeachIcon from '@/assets/images/search_icon.svg'

const Header = () =>{

    const location = useLocation()
    const navigate = useNavigate();
    const {signOut,isLoggedIn}=useUser()
    const Root = location.pathname.split('/')[1]
    //console.log(Root)
    const [moNav,setMoNav]=useState(false)

    const [stateHeadSearch, setStateHeadSearch] = useState('')
    const EventHeadSearch = (e:string) => {
        setStateHeadSearch(e)
    }

    const handleMoNav = () =>{
        setMoNav(!moNav)
    }
    const [moMyGnb,setMoMyGnb]=useState(false)
    const handleMoMyGnb = () =>{
        setMoMyGnb(!moMyGnb)
    }
    const handleLink = (e:string)=>{
        setMoNav(false);
        navigate(e);
    }
    const handleMoSignOut = ()=>{
        setMoNav(false);
        signOut();
    }
    useEffect(() => {

    }, [stateHeadSearch]);

    const HandleHeadSearch = () =>{
        // alert(stateHeadSearch)
        navigate(`/content/Content?keyword=${stateHeadSearch}`)
    }


    const storeToken = store.getState().auth.token

    //console.log(storeToken)
    console.log('ver.57')

    return (
        <>
            <ScrollToTop/>
            <HeaderWrap>
                <div className="header">
                    <button
                        className="header--ham"
                        // onClick={handleMoNav}
                    >
                        {/* <img src={IcHam} alt=""/> */}
                    </button>
                    {/* <Link to='./' className="header--logo">
                        <img src={Logo} alt=""/>
                    </Link> */}
                    <a className="header--logo" href='https://8wmz.adj.st/navigation?scene=home&adj_t=19n2rjwi'>
                        <img src={Logo} alt=""/>
                    </a>
    
                    <ul className="header--gnb">
                        {/* <li>
                            <Link className={cx({
                                active:Root==='company'
                            })} to='/company/Company'>회사소개</Link>
                        </li>
                        <li>
                            <Link className={cx({
                                active:Root==='business'
                            })} to='/business/Business'>서비스소개</Link>
                        </li>
                        <li>
                            <Link className={cx({
                                active:Root==='content'
                            })} to='/content/Content'>콘텐츠</Link>
                        </li>
                        <li>
                            <Link className={cx({
                                active:Root==='nutrientReport'
                            })} to='/nutrientReport/NutrientReport'>영양제 정보</Link>
                        </li>
                        <li>
                            <Link className={cx({
                                active:Root==='board'
                            })} to="/board/faq/Faq">공지/문의</Link>


                        </li> */}
                    </ul>
    
                    <div className="header--sub">
                        {/*
                        <div className="header--search">
                            <button
                                onClick={HandleHeadSearch}
                                className="header--search--btn"
                            >
                                <img src={SeachIcon} alt=""/>
                            </button>
                            <input
                                className="header--search--input"
                                placeholder="콘텐츠를 검색해보세요!"
                                type="text"
                                onChange={(e)=>setStateHeadSearch(e.target.value)}
                            />
                        </div>
                        */}

                        {/* <div className="header--sub--btn">
                            {!isLoggedIn?(
                                <Link to="/user/login/Login">
                                    <img src={IcLogin} alt="로그인"/>
                                    로그인
                                </Link>
                            ):(
                                <>
                                    <button onClick={signOut}>
                                        <img src={IcLogout} alt="로그인"/>
                                        로그아웃
                                    </button>
                                    <Link to="/user/mypage/profile">
                                        <img src={IcUser} alt=""/>
                                    </Link>
                                </>
                            )}
                        </div> */}
                        {/* */}
                    </div>
                </div>
            </HeaderWrap>
            {/* {moNav&&(
                <MoNav>
                    <div className="MoNav--head">
                        <img src={LogoWhite} alt=""/>

                        <div className="MoNav--controller">
                            {!isLoggedIn?(
                                <button
                                    className="MoNav--logBtn"
                                    onClick={()=>handleLink('/user/login/Login')}
                                >로그인</button>
                            ):(
                                <button className="MoNav--logBtn" onClick={handleMoSignOut}>로그아웃</button>
                            )}

                            <button
                                className="MoNav--close"
                                type="button"
                                onClick={handleMoNav}
                            >
                                <img src={IcClose} alt=""/>
                            </button>
                        </div>

                    </div>
                    <div className="MoNav--cnt">
                        <ul className="MoNav--gnb mt-30">
                            <li>
                                <button onClick={()=>handleLink('/')}>홈</button>
                            </li>
                            <li>
                                <button onClick={()=>handleLink("/company/Company")}>회사소개</button>
                            </li>
                            <li>
                                <button onClick={()=>handleLink("/business/Business")}>서비스소개</button>
                            </li>
                            <li>
                                <button onClick={()=>handleLink("/content/Content")}>콘텐츠</button>
                            </li>
                            <li>
                                <button onClick={()=>handleLink("/nutrientReport/NutrientReport")}>영양제 정보</button>
                            </li>
                            <li>
                                <button onClick={()=>handleLink("/board/faq/Faq")}>공지/문의</button>
                            </li>
                        </ul>

                        {isLoggedIn&&(
                        <dl className="MoNav--myGnb mt-30">
                            <dt onClick={handleMoMyGnb}>
                                마이페이지
                                <img
                                    src={IcArrowDown}
                                    alt=""
                                    className={cx('',{
                                        active:moMyGnb===true
                                    })}
                                />
                            </dt>
                            <dd className={cx('',{
                                active:moMyGnb===true
                            })}>
                                <ul className="MoNav--gnb">
                                    <li>
                                        <button onClick={()=>handleLink("/user/mypage/profile")}>프로필 관리</button>
                                    </li>
                                    <li>
                                        <button onClick={()=>handleLink("/user/mypage/interview")}>문진 결과</button>
                                    </li>
                                    <li>
                                        <button onClick={()=>handleLink("/user/mypage/payment")}>결제 관리</button>
                                    </li>
                                    <li>
                                        <button onClick={()=>handleLink("/user/mypage/delivery")}>배송 정보 관리</button>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        )}
                    </div>
                </MoNav>
            )} */}
        </>
    )
}
export default Header;