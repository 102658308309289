import Styled from 'styled-components';

//리스트
export const BoardListWrap = Styled.div`
    section{
        padding:100px 0;
    }
    background:#FCFDFD;
`

export const BoardListSearch = Styled.div`
    width:100%;
    height:48px;
    display:flex;
    align-items:stretch;
    justify-content:center;
    border-radius:32px;
    //border:1px solid #cecfcf;
    box-shadow:0 5px 10px rgba(0,0,0,0.06);
    border:1px solid rgba(0,0,0,0.15);
    overflow:hidden;
    margin-top:60px;
    .btn{
        width:50px;
                background:#29a36c;
                border-top-right-radius:25px;
                border-bottom-right-radius:25px;
                cursor:pointer;
                img{
                    transform:translateX(-3px);
                }
    }
    .input{
        width:calc(100% - 50px);
        text-indent:34px;
        font-size:18px;
        color:#787e8b;
    }
    @media (max-width: 1024px){
        width:100%;
        .input{
            font-size:15px;
            text-indent:20px;
        }
    }
`

export const BoardListTab = Styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
    width:100%;
    a{
        padding-bottom:20px;
        width:50%;
        display:flex;
        justify-content:center;
        font-size:22px;
        font-weight:600;
        color:#827d7c;
        border-bottom:1px solid #827d7c;
        box-sizing:border-box;
        @media (max-width: 768px){
            font-weight:300;
            font-size:20px;
            padding-bottom:10px;
        }
        &.active{
            color:#1f1e1d;
            // font-weight:900;
            border-bottom:3px solid #01b763;
            @media (max-width: 768px){
                font-weight:600;
            }
        }
    }

`

export const BoardListTag = Styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    flex-wrap:wrap;
    gap:10px;
    margin:20px 0 0;
    a, button{
        color:#fff;
        background:#d9d9d9;
        font-size:18px;
        padding:10px 20px;
        border-radius:8px;
        &.active{
            background:#000;
        }
    }
`

export const BoardListCnt = Styled.section`
    width:90%;
    // max-width:960px;
    max-width:750px;
    margin-right:auto;
    margin-left:auto;
    .board{
        &--footer{
            padding:10px 20px;
            background:rgba(0,0,0,0.04);
            display:flex;
            align-items:center;
            justify-content:space-between;
            margin-top:60px;
            border-radius:15px;
            flex-wrap:wrap;
            p{
                font-size:18px;
            }
            @media (max-width: 768px){
                justify-content:center;
                gap:20px;
            }
        }
        &--tit{
            font-size:40px;    
            font-weight:600;
            margin-bottom:40px;
        }
        &--list{
           margin-top:30px;
           width:100%; 
        }
        &--item{
            //padding:25px 20px;
            padding:15px 10px;
            box-sizing:border-box;
            // box-shadow:0 2px 8px rgba(0,0,0,0.08);
            // border:1px solid rgba(0,0,0,0.04);
            box-shadow:0 5px 10px rgba(0,0,0,0.06);
            border:1px solid rgba(0,0,0,0.15);
            margin-bottom:15px;
            border-radius:20px;
            @media (max-width: 768px){
                padding:15px 10px;
                border-radius:10px;
            }
            &--head{
                display:flex;
                align-items:center;
                justify-content:space-between;
                &--tit{
                    width:calc(100% - 40px);
                    a, button{
                        display:block;
                        // font-size:20px;
                        font-size:18px;
                        font-weight:600;
                        line-height:1.3;
                        color:#1d1d1d;
                        width:100%;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap;
                        letter-spacing:-0.5px;
                        text-align:left;
                        cursor:pointer;
                        text-indent:3px;
                        @media (max-width: 768px){
                            font-size:16px;
                            // font-weight:300;
                        }
                    }
                    &.question{
                        a, button{
                             &::before{
                                content:'Q. ';
                                font-size:20px;
                                font-weight:200;
                                color:#01B763;
                                // font-family:"Din","GmarketSans","Pretendard","Euclid", "NotoSansKR";
                            }
                        }
                    }
                    &--sub{
                        display:flex;
                        align-items:center;
                        justify-content:flex-start;
                        gap:15px;
                        margin-left:10px;
                        p{
                            font-size:13px;
                            font-weight:200;
                            line-height:1;
                            // color:#828282;
                            color:#000;
                            margin-top:5px;
                            display:flex;
                            align-items:center;
                            justify-content:flex-start;
                            gap:5px;
                            img{
                                height:12px;
                                &.viewCount{
                                    height:16px;
                                }
                            }
                        }
                    }
                }
                &--btn{
                    width:40px;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    cursor:pointer;
                    transition-duration:0.5s;
                    transform:rotate(180deg);
                    margin-right:10px;
                    img{
                        width:100%;
                    }
                }
                &--aTit{
                    display:block;
                    font-size:20px;
                    font-weight:600;
                    line-height:1.25;
                    color:#1d1d1d;
                    width:100%;
                    letter-spacing:-0.5px;
                    text-align:left;
                    padding:20px 0;
                    margin:20px 0;
                    box-sizing:border-box;
                    border-top:1px solid rgba(0,0,0,0.1);
                    border-bottom:1px solid rgba(0,0,0,0.1);
                    &::before{
                        content:'A. ';
                        font-size:20px;
                        font-weight:200;
                        color:#01B763;
                        // font-family:"Din","GmarketSans","Pretendard","Euclid", "NotoSansKR";
                    }
                }
            }
            &--body{
                max-height:0;
                overflow:hidden;
                transition-duration:0.5s;
                &--cnt{
                    border-top:1px solid rgba(0,0,0,0.1);
                    margin-top:20px;
                    // font-size:18px;
                    line-height:1.6;
                    word-break:break-all;
                    padding:20px 10px 0;
                    box-sizing:border-box;
                    *{
                        font-size:unset;
                        font-style:unset;
                        // font-weight:unset;
                        font-family:unset;
                        color:unset;
                        list-style:circle;
                        list-style-type: circle;
                        margin:unset;
                        padding:unset;
                        line-height:unset;
                        word-break:break-all;
                    }
                    pre{
                        overflow: auto;
                        white-space: pre-wrap
                    }
                    &--item{
                        padding:0 15px;
                        box-sizing:border-box;
                    }
                    &--tit{
                        font-size:18px;
                        font-weight:600;
                        margin-bottom:10px;
                    }
                    &--cnt{
                        font-size:18px;
                        font-weight:300;
                        line-height:1.6;
                        margin-bottom:20px;
                    }
                    &--article{
                        display:flex;
                        flex-direction:column;
                        align-items:flex-start;
                        justify-content:center;
                        gap:20px;
                        video, img{
                            max-width:100%;
                        }
                    }
                }
                &--aCnt{
                    font-size:18px;
                    line-height:1.6;
                    word-break:break-all;
                    padding:20px 10px 10px 30px;
                    box-sizing:border-box;
                    margin-top:20px;
                    position:relative;
                    border-top:1px solid rgba(0,0,0,0.1);
                    box-sizing:border-top;
                    white-space: pre-line;
                    &::before{
                        content:'A. ';
                        font-size:20px;
                        font-weight:200;
                        color:#01B763;
                        position:absolute;
                        top:18px;
                        left:10px;
                    }
                    
                }
            }
            &.active{
                .board--item--head{
                    
                }
                .board--item--head--tit{
                    a, button{
                        overflow:initial;
                        text-overflow:initial;
                        white-space:initial;
                        // margin-bottom:10px;
                    }
                }
                .board--item--body{ 
                    transition-duration:0.5s;
                    // max-height:300px;
                    max-height:600px;
                    overflow-y:scroll;
                }
                .board--item--head--btn{
                    transition-duration:0.5s;
                    transform:rotate(0deg);
                }
            }
        }
    }
`

export const BoardViewWrap = Styled.section`
     section{
        padding:100px 0;
    }
    background:#FCFDFD;
    min-height:80vh;
`

export const BoardViewCnt = Styled.section`
    width:90%;
    max-width:720px;
    margin-right:auto;
    margin-left:auto;
    .boardViewCnt{
        &--tit{
            font-size:36px;
            font-weight:900;
            line-height:1.2;
            color:#232323;
        }
        &--date{
            margin-top:20px;
            font-size:16px;
            font-weight:600;
            line-height:1.2;
            color:#484848;
        }
        &--txt{
            margin-top:80px;
            margin-bottom:80px;
            font-size:16px;
            font-weight:600;
            color:#252525;
            line-height:1.33;
            word-break:break-all;
        }
    }
`

export const AskCnt = Styled.div`
    width:90%;
    max-width:890px;
    margin-left:auto;
    margin-right:auto;
    .AskTable{
        width:100%;
        th{
            width:105px;
            vertical-align:top;
            font-size:16px;
            font-weight:500;
            color:#616169;
            text-align:left;
        }
        td{
            width:calc(100% - 105px);
            padding-bottom:16px;
            .msg{
                font-size:12px;
                color:#01B763;
                vertical-align:bottom;
                line-height:2;
            }
            p{
                font-size:14px;
                font-weight:500;
                color:#616169;
                margin-top:20px;
            }
            input[type^='text']{
                width: 100%;
                height: 38px;
                background: #fff;
                border: 1px solid #e4e4e7;
                border-radius: 4px;
                box-sizing: border-box;
                text-indent: 15px;
            }
            textarea{
                width: 100%;
                height: 250px;
                background: #fff;
                border: 1px solid #e4e4e7;
                border-radius: 4px;
                box-sizing: border-box;
                padding: 15px;
                resize:none;
            }
        }
        &--caption{
            margin-top:15px;
            display:flex;
            align-items:center;
            justify-content:flex-start;
            flex-wrap:wrap;
            line-height:1.4;
            a, button{
                font-size:14px;
                font-weight:600;
                color:#000;
                border-bottom:1px solid #000;
                margin-left:10px;
                cursor:pointer;
            }
            input[type^='checkbox']{
                display:none;
                & + label{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 13px;
                    font-weight: 600;
                    color: #000;
                    gap: 7px;
                    cursor: pointer;
                    position: relative;
                    line-height: 1;
                    vertical-align: bottom;
                    cursor: pointer;
                    span{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 13px;
                        font-weight: 600;
                        color: #000;
                        gap: 7px;
                        cursor: pointer;
                        position: relative;
                        line-height: 1;
                        vertical-align: bottom;
                        cursor: pointer;
                        width: 13px;
                        height: 13px;
                        border-radius: 2px;
                        border: 1px solid #8d8d8f;
                        box-sizing: border-box;
                        transform: translateY(-1px);
                    }
                }
                &:checked + label span::after{
                    content: '';
                    width: 60%;
                    height: 60%;
                    display: block;
                    background: #01B763;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    border-radius: 2px;
                }
            }
        }
    }
    .AskTable--btn{
        margin-top:60px;
        text-align:center;
        max-width:90%;
        margin-left:auto;
        margin-right:auto;
        button{
            cursor: pointer;
            border: none;
            outline: none;
            transition: all 0.2s;
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            max-width: 100%;
            width: 100%;
            height: 64px;
            color: #fff;
            background: #000;
            border-radius: 8px;
            box-sizing: border-box;
            font-size:18px;
            font-weight:900;
            color:#fff;
        }
    }
`
