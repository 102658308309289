import React, { ButtonHTMLAttributes } from 'react'
import { Link } from "react-router-dom"
import styled, { css } from 'styled-components'

export type propTypes = {
    to?:string|any
    id?:string
    width?:string
    height?:string
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
    children?: React.ReactNode; // make the component able to receive children elements
    color?: 'primary' | 'secondary' | 'recommendBadge' | 'black' | 'lightGray'; // two styling options (you can create as many as you want)
    disabled?: boolean; // make the button disabled or not
    radius?:string
    badgeMode?:boolean
}

const COLOR = {
    primary: css`
    color: #fff;
    background: #01b763;
  `,
    secondary: css`
    color: #fff;
    background: #ffd68e;
  `,
    recommendBadge: css`
    background:#F4F6FA;
    color: #787e8b;
    font-size:16px;
    font-weight: 400;
    padding:10px 15px;
  `,
    black: css`
    color: #fff;
    background: #000;
  `,
    lightGray: css`
    color: #787e8b;
    background: #f4f6fa;
  `,
};

const DISABLED = css`
  cursor: not-allowed;
  background: #d4d4d4;
  color: #f5f5f5;
`;

export const BTNContainer = styled.button<propTypes>`
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.2s;
  text-decoration:none;
  display:inline-flex;
  align-items:center;
  justify-content:center;
  // gap:20px;
  padding:0 12px;
  max-width:100%;
  width:${(props) => props.width && props.width};
  height:${(props) => props.height && props.height};
  ${(props) => props.color && COLOR[props.color]}
  ${(props) => props.disabled && DISABLED}
  border-radius:${(props) => props.radius ? props.radius :`8px`};
  box-sizing:border-box;
`;

export const LINKContainer = styled.a<propTypes>`
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.2s;
  text-decoration:none;
  display:inline-flex;
  align-items:center;
  justify-content:center;
  // gap:20px;
  padding:0 10px;
  max-width:100%;
  width:${(props) => props.width && props.width};
  height:${(props) => props.height && props.height};
  ${(props) => props.color && COLOR[props.color]}
  ${(props) => props.disabled && DISABLED}
  border-radius:${(props) => props.radius ? props.radius :`8px`};
  box-sizing:border-box;
`;

const Button = ({
    to,
    id,
    onClick,
    children,
    color,
    width,
    height,
    disabled,
    radius,
    badgeMode
}:propTypes) => {

    return (
        <>
            {onClick&&(
                <BTNContainer
                    onClick={onClick}
                    id={id}
                    color={color}
                    width={width}
                    height={height}
                    disabled={disabled}
                    radius={radius}
                >
                    {children}
                </BTNContainer>
            )}
            {to&&(
                <LINKContainer
                    href={to}
                    id={id}
                    color={color}
                    width={width}
                    height={height}
                    disabled={disabled}
                    radius={radius}
                >
                    {children}
                </LINKContainer>

            )}
            {badgeMode&&(
                <LINKContainer
                    id={id}
                    color={color}
                    width={width}
                    height={height}
                    disabled={disabled}
                    radius={radius}
                >
                    {children}
                </LINKContainer>
            )}
        </>
    )
}

export { Button }
