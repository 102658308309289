import axios from 'axios';
import { useDispatch, useSelector } from "react-redux"
import { authActions, AuthInitialState } from "@/store/auth-slice"
import TokenProvider from "@/utils/token-provider"
import {store} from "@/store/store"
import moment from "moment"
import jwt_decode , { JwtPayload } from "jwt-decode";
//import JWT from 'jsonwebtoken'

const API = process.env.REACT_APP_API_URL;

type tokenProps = JwtPayload & {
    sub:string
    roles:string[]
    exp:number
}


export const authClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
})

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    // Accept: 'application/json',
  },
  timeout: 10000,
  withCredentials: true, // refresh token을 위해 필요
});

// 요청 인터셉터
httpClient.interceptors.request.use(
    async function (config) {

        const storeToken = store.getState().auth.token

        const isNotExpires = TokenProvider.isNotExpires(storeToken)

        if (storeToken) {
            if (!isNotExpires) {

                try{
                    const {data:refreshData} = await authClient.post("/user-service/v1/auth/refresh", {
                        refreshToken: storeToken?.refreshToken,
                        accessToken: storeToken?.accessToken

                    }, {})

                    store.dispatch(authActions.setToken({...refreshData.data,id:storeToken?.id}))
                    //console.log('need refresh',refreshData,store.getState().auth)

                }catch (e) {
                    store.dispatch(authActions.clearToken())
                }

            } else {
                //console.log('dont need refresh',store.getState().auth)
                return config
            }
        }

        return config

    },function (error) {
        return Promise.reject(error)
    }
)


// 응답 인터셉터
httpClient.interceptors.response.use(
    function (response) {
      // http status 200인 경우 응답 성공 직전 호출된 후 then으로 이어집니다.

      return response
    },
    async function (error) {
      // http status 200이 아닌 경우 응답 에러 직접 호출된 후 catch으로 이어집니다.
        const storeToken = store.getState().auth.token

        const isNotExpires = TokenProvider.isNotExpires(storeToken)

        const originalRequest = error.config;

        return Promise.reject(error)
    },
)