import { AxiosPromise } from "axios"
import { useMutation, useQuery } from "react-query"
import qs from "query-string"
import { httpClient } from "./axios"
import { ApiResponse } from "./common.model"

export interface JoinRequest {
	// birth_day?: string
	// birth_year?: string
	//gender?: "m" | "f"
	//join_ch?: string
	// join_type: "email" | "sns"
	id: string
	type: "KAKAO" | "NAVER" | "GOOGLE" | "APPLE"
	name?: string
	email?: string
	device_unique?: string
	device_group?: string
	device_num?: string
	service_agree?: "Y" | "N"
	privacy_agree?: "Y" | "N"
	marketing_agree?: "Y" | "N"
	phone?: string
	sns_token?: string
}

class JoinService {
	/**
	 * 인증번호 발송
	 */
	sendAuthNumber(payload: {
		phone: string
	}): AxiosPromise<ApiResponse<{ auth_key: string }>> {
		return httpClient.post("/join/auth", null, {
			params: payload,
		})
	}
	/**
	 * 인증번호 체크
	 */
	authNumberCheck(payload: {
		auth_number: string
		phone: string
		auth_key: string
	}): AxiosPromise<ApiResponse<{ success: boolean }>> {
		return httpClient.post("/join/auth-check", qs.stringify(payload))
	}
	
	/**
	 * 회원가입
	 */
	async join(payload: JoinRequest) {
		return httpClient.post("/join/join", qs.stringify(payload))
	}
}

const joinService = new JoinService()

export default joinService

/**
 * 인증번호 발송
 */
export function useJoinSendAuthNumber() {
	const { mutateAsync } = useMutation(joinService.sendAuthNumber)
	return mutateAsync
}

/**
 * @typedef {object} AuthNumberCheckParam
 * @property {!string} auth_number 인증번호
 * @property {!string} phone 휴대폰번호
 *
 * @callback AuthNumberCheck
 * @param {AuthNumberCheckParam} param
 * @returns {Promise}
 */

/**
 * 인증번호 체크
 * @return {AuthNumberCheck}
 */
export function useJoinAuthNumberCheck() {
	const { mutateAsync } = useMutation(joinService.authNumberCheck)
	return mutateAsync
}


/**
 * @typedef {object} JoinParam
 * @property {!string} email 회원 이메일
 * @property {?('1'|'0')} email_yn 이메일 수신 여부 (1: Y, 0: N) (Optional)
 * @property {?('K'|'N'|'F'|'G'|'A')} join_ch 가입 채널 (K: 카카오, N: 네이버, F: 페이스북, G: 구글, A: 애플) (Optional)
 * @property {!('email'|'sns')} join_type 가입 방법 (email: 이메일, sns: 소셜)
 * @property {!string} name 회원 이름
 * @property {!string} password 회원 비밀번호
 * @property {!string} phone 휴대폰 번호
 * @property {?('1'|'0')} sms_yn SMS 수신 여부 (1: Y, 0: N) (Optional)
 *
 * @callback Join
 * @param {JoinParam} param
 * @returns {Promise}
 */

/**
 * 회원가입
 * @return {Join}
 */
export function useJoin() {
	const { mutateAsync } = useMutation(joinService.join)

	return mutateAsync
}
