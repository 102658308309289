import React,{useState} from 'react'
import qs from "query-string"
import { 
    useBbsList, 
    BbsListResponseItem, 
    useBbsCategoryList,
    useBbsView
} from "@/config/api/bbsService"
import {Link, useNavigate, useLocation} from "react-router-dom"
import Pagination from 'rc-pagination'
import cx from "classnames"
import {
    ReactChannelIO,
    useChannelIOApi,
    useChannelIOEvent
} from "react-channel-plugin";
import {DateFomat} from '@/utils/dateFomat'
import {Button} from '@/components/button'
import {BoardBanner} from '@/containers/banner/index'
import {PaginationWrap} from '@/assets/style/paginationStyle'
import { BoardListWrap, BoardListSearch, BoardListTag, BoardListTab, BoardListCnt } from '../styled'
import NutrientItem from '@/assets/images/nutrientReport/NutrientItem.png'
//import IcArrowDown from '@/assets/images/ic-arrowDown.png'
import IcArrowDown from '@/assets/images/btnTop.png'
import IcCalendar from '@/assets/images/ic-calendar.png'
import IcView from '@/assets/images/ic-view.png'
import SeachIcon from '@/assets/images/search_icon.svg'
import IcSearch from '@/assets/images/ic-search.png'

const Faq = () => {

  const searchParams = useLocation().search;
  const query = qs.parse(searchParams);
  const keyword = new URLSearchParams(searchParams).get('keyword');
  const ctgy = Number(new URLSearchParams(searchParams).get('main_ctgr_id'));
  const navigate = useNavigate();

  const [stateSearch, setStateSearch] = useState<any>(keyword)
  const [stateCategory, setStateCategory] = useState<number|undefined>(ctgy)

  const [current, setCurrent] = useState(1);

  //console.log(keyword)

  const {data:cateList} =useBbsCategoryList({
      // page:current-1,
      page:0,
      // limit:10,
      //search:keyword?keyword:'',
  })

  const { data } =useBbsList({
        fieldType:'FAQ',
        page:current-1,
        limit:10,
        search:keyword?keyword:'',
        main_ctgr_id:ctgy?ctgy:null
  })

  //const [id,setId]=useState<any>(undefined)

  //const { data:viewData } =useBbsView(id)


    //view
    
  const onChange = (page:number) => {
        setCurrent(page);
  };

  const HandleHeadSearch = () =>{
        // alert(stateHeadSearch)
      // alert(stateCategory)
      if(stateSearch ==='') {
          navigate(`/board/faq/Faq?main_ctgr_id=${stateCategory}`)
      }else{
          navigate(`/board/faq/Faq?keyword=${stateSearch}&main_ctgr_id=${stateCategory}`)
      }
  }

  const handleTab = (e) =>{
      setStateCategory(e)
      if(stateSearch===null){
          navigate(`/board/faq/Faq?keyword=&main_ctgr_id=${e}`)
      }else{
          navigate(`/board/faq/Faq?keyword=${stateSearch}&main_ctgr_id=${e}`)
      }

  }

  const handleKeypress = (e)=>{
      if(e.key==='Enter'){
          HandleHeadSearch()
      }
  }

  const [ activeView, setctiveView] = useState(0)

    const dropDown = (e:number|any) => {
        if(activeView===e){
            setctiveView(0)
        }else{
            setctiveView(e)
            //setId(e)
        }
    }

    const showMessenger =() =>{ alert('준비 중입니다.')};
  
    /*
    const { showMessenger } = useChannelIOApi();
    useChannelIOEvent("onShowMessenger", () => {
        console.log("Messenger opened!");
    });
   */

    //const insertCnt =`${data?.data.content.}`;
    //Kakao.init("831b3e1f-11a3-462a-b139-d3e106ae8c43");

    /*
    ChannelIO('boot', {
        "pluginKey": "831b3e1f-11a3-462a-b139-d3e106ae8c43" // fill your plugin key
    });


  const handleAsk = () =>{
      var w=window;
      if(w.ChannelIO){
          return w.console.error("ChannelIO script included twice.");
      }
      var ch=function(){
          ch.c(arguments);
      };
      ch.q=[];
      ch.c=function(args){
          ch.q.push(args);
      };
      w.ChannelIO=ch;
      function l(){
          if(w.ChannelIOInitialized){
              return;
          }
          w.ChannelIOInitialized=true;
          var s=document.createElement("script");
          s.type="text/javascript";
          s.async=true;s.src="https://cdn.channel.io/plugin/ch-plugin-web.js";
          var x=document.getElementsByTagName("script")[0];
          if(x.parentNode){
              x.parentNode.insertBefore(s,x);
          }
      }
      if(document.readyState==="complete"){
          l();
      }else{
          w.addEventListener("DOMContentLoaded",l);
          w.addEventListener("load",l);
      }
  }
  console.log(data?.data)
  */

  return (
      <BoardListWrap>
          <BoardBanner/>
          <BoardListCnt>
              <BoardListTab>
                  <Link to="/board/faq/Faq" className="active">자주 묻는 질문</Link>
                  <Link to="/board/notice/Notice" className="">공지사항</Link>
              </BoardListTab>

              <BoardListSearch>
                  <input
                      className="input"
                      placeholder="이퀄과 관련한 문의사항을 검색해 보세요!"
                      type="text"
                      onChange={(e)=>setStateSearch(e.target.value)}
                      onKeyPress={(e)=>{
                          handleKeypress(e)
                      }}
                  />
                  <button
                      onClick={HandleHeadSearch}
                      className="btn"
                  >
                      <img src={IcSearch} alt=""/>
                  </button>
              </BoardListSearch>
              {/*
              <BoardListTag>
                  <button
                      className={cx('',{
                          active:!ctgy
                      })}
                      onClick={(e)=>handleTab(null)}
                  >전체보기</button>
                  {cateList?.data.content.map((item)=>(
                      <button
                          className={cx('',{
                              active:item.id===ctgy
                          })}
                          onClick={(e)=>handleTab(item.id)}
                          key={item.id}
                      >{item.name}</button>

                  ))}
                  
              </BoardListTag>
              */}
              <ul className="board--list">
                  {data?.data.content.map((item)=>(
                      <li key={item.id} className={cx("board--item",{
                          active:item.id===activeView
                      })}>
                          <div className="board--item--head">
                              <div className="board--item--head--tit question">
                                  <button onClick={()=>dropDown(item.id)} className="board--list--tit">
                                      {item.title}
                                  </button>
                                  {/*
                                  <div className="board--item--head--tit--sub">
                                      <p>
                                          <img src={IcCalendar} alt=""/>
                                          {item.update_date?
                                              DateFomat(item.update_date):
                                              DateFomat(item.insert_date)
                                          }
                                      </p>
                                  </div>
                                  */}
                              </div>
                              <button  onClick={()=>dropDown(item.id)} className="board--item--head--btn">
                                  <img src={IcArrowDown} alt=""/>
                              </button>
                          </div>
                          <div className="board--item--body">
                              {/*
                              <div className="board--item--body--cnt">
                                  {item.contents}
                              </div>
                              <div className="board--item--head--aTit">
                                      {item.answer_id}
                              </div>
                              */}
                              <div
                                  className="board--item--body--aCnt"
                                  dangerouslySetInnerHTML={{__html:item.contents}}
                              />
                              {/*{item.contents}*/}
                              {/*</div>*/}
                          </div>
                      </li>
                  ))}
              </ul>
              <PaginationWrap>
                  <Pagination onChange={onChange} current={current} total={data?.data.totalElements} />
              </PaginationWrap>
              <div className="board--footer" >
                  <p>
                      찾으려는 답변이 없으신가요?
                      {/*원하시는 정보가 없거나 궁금한 부분이 해결되지 않았다면 1:1문의를 접수해주세요.*/}
                  </p>

                  <Button
                      to="/board/ask/Ask"
                      //onClick={showMessenger}
                      // to="mailto:contact@equal.pet"
                      color='black'
                      height='50px'
                      width='130px'
                  >
                      <span style={{ fontSize:'18px',fontWeight:'600',letterSpacing:'1px' }}>1:1문의</span>
                  </Button>
              </div>
          </BoardListCnt>
      </BoardListWrap>
  )
}

export default  Faq
