import React,{useMemo} from "react"
import { useDispatch, useSelector } from "react-redux"
import TokenProvider from "@/utils/token-provider"
import { Link, useLocation,useNavigate } from 'react-router-dom'
import { v4 as uuid } from "uuid"
// import loginService, { LoginPayload } from "@/api/login.service"
import loginService, { LoginPayload, LoginRequest } from "@/config/api/loginService"
import joinService, { JoinRequest } from "@/config/api/joinService"
import passwordProvider from "@/utils/password-provider"
// import getDeviceId from "../lib/getDeviceId"
import getDeviceId from "@/utils/getDeviceId"
import { authActions, AuthInitialState } from "@/store/auth-slice"
import { RootState } from "@/store/store"
import { httpClient } from "@/config/api/axios"
import qs from "query-string"
// import jwt from "jsonwebtoken"
//import TagManager from "react-gtm-module"

declare const Kakao: any
declare const window: any

export const providerExtractor = {
	KAKAO: (response): LoginRequest => {
		//console.log("kakao response", response)
		const { profile } = response
		const { kakao_account, properties } = profile

		return {
			id: profile.id,
			type: "KAKAO",
			// name: properties.nickname,
			email: kakao_account.email,
			service_agree:"Y",
			privacy_agree: "Y",
			device_unique: "deviceuniquenumber0002",
			device_group: "A",
			device_num: "fcm_token_000002",
			// phone: kakao_account?.phone_number,
			//password: passwordProvider.encode(uuid()),
		}
	},
	NAVER: (response): JoinRequest => {
		const { user } = response


		let mobile = undefined
		if (user.mobile) {
			mobile = user.mobile.replaceAll("-", "")
		}
		return {
			type: "NAVER",
			email: user.email,
			id: user.id,
			name: user.nickname,
			//password: passwordProvider.encode(uuid()),
			//birth_day: user.birday,
			phone: mobile

		}
	},
}

const useUser = () => {
	// const state = useSelector<RootState, AuthInitialState>((state) => state.auth)
	const state = useSelector<RootState, AuthInitialState>((state) => state.auth)

	const dispatch = useDispatch()

	const handleSignOut = () => {

		console.log('success logout');
		// 카카오 로그아웃

		TokenProvider.clear()
		// if (Kakao.Auth.getAccessToken()) {
		// 	console.log("kakao logout");
		// 	Kakao.Auth.logout()
		// }

		// 네이버에서 로그아웃
		if (window.naverLogin) {
			window.naverLogin.logout()
		}
		//
		dispatch(authActions.clearToken())
		window.location.href="/user/login/Login"
	}

	const handleSignIn = async (payload: LoginRequest) => {
		const device_group = getDeviceId()
		const push_token = uuid()

		const { data } = await loginService.login({
			...payload
		})
		const  token  = data?.data
		/*
			TagManager.dataLayer({
				dataLayerName: "LoginSuccessLayer",
				dataLayer: {
					type: "login",
				},
			})
		*/

		//console.log('data:',data,'token:',token)

		dispatch(authActions.setToken(data))
		//dispatch(authActions.setToken(token))
	}

	const handleSignUp = async (payload: JoinRequest) => {
		await joinService.join(payload)
	}

	const isLoggedIn = React.useMemo(() => {
		return TokenProvider.verify(state.token)
	}, [state.token])

	/**
	 * sns 계정이, 이미 가입되어 있는지 체크한다.
	 * @returns {Promise<boolean>}
	 */
	/*
	const handleCheckSnsAccount = async ({ params }) => {
		try {
			await httpClient.get(
				"/user-service/v1/auth/check/signup",{
					params: {
						id: params.id,
						type:params.type,
					}
				}
				// qs.stringify({
				// 	join_ch,
				// 	sns_token,
				// }),
			)
			return true
		} catch (e) {
			return false
		}
	}
	*/

	return {
		isLoggedIn: isLoggedIn,
		signOut: handleSignOut,
		signIn: handleSignIn,
		signUp: handleSignUp,
		//checkSns: handleCheckSnsAccount,
	}
	
}

export default useUser
