//import crypto from "crypto-js"
import { v4 as uuid } from "uuid"

function getDeviceId() {
	let dToken = localStorage.getItem("user_d_token")

	// if (!dToken) {
	// 	dToken = crypto.SHA256(uuid()).toString(crypto.enc.Hex)
	// 	localStorage.setItem("user_d_token", dToken)
	//	}

	return dToken
}

export default getDeviceId
