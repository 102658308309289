import React, { ReactEventHandler, useEffect } from "react"
import { styled } from "styled-components"

const Container = styled.div`
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
`

const Video = styled.video`
	width: 600px;
	height: 600px;
`

export default function SplashRedirect({
	redirectURL,
}: {
	redirectURL?: string
}) {
	useEffect(() => {
		const timer = setTimeout(() => {
			if (redirectURL) {
				window.location.href = redirectURL
			}
		}, 2000)
		return () => clearTimeout(timer)
	}, [redirectURL])

	return (
		<Container>
			<Splash
				onEnded={() => {
					if (redirectURL) {
						window.location.href = redirectURL
					}
				}}
			/>
		</Container>
	)
}

function Splash({ onEnded }: { onEnded: ReactEventHandler<HTMLVideoElement> }) {
	return (
		<Video onEnded={onEnded} autoPlay muted playsInline>
			<source src="videos/splash.mp4?20240206" type="video/mp4" />
		</Video>
	)
}
