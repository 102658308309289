import {QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from "react-query"
import { httpClient } from "./axios"
import { ApiResponse } from "./common.model"

export interface BbsListRequest{
    limit?:number
    page?:number
    search?:string
    fieldType?:string
    animal?:string
    user_id?:number
    tag_id?:number
    main_ctgr_id?:number|null
}

export interface BbsCategoryRequest{
    page?:number //	페이지
    limit?:number //페이지 당 데이터 수
    search?:string //검색어
}

export interface BbsListResponseItem{
    id?: number,
    insert_user?: number,
    update_user?: boolean,
    insert_date?: string,
    update_date?: string,
    type: "notice"|"inquiry"|"FAQ"|"event",
    main_ctgr_id?:number,
    views?: number,
    title?: string,
    contents: string,
    use_yn?: string,
    delete_yn?: string,
    tag?: string,
    answer_id?: null|number,
    pet_id?: number,
    insert_user_info?: {
        providerId?: string,
        name?:string,
        email?: string,
        emailVerifiedYn?: string,
        profileImageUrl?: string,
        nickname?: string,
        gender?: string,
        birth?: string,
        phone?: string,
        carrier?: string,
        address?: string,
        address_detail?: string,
        type?: string,
        uid?: string,
        use_yn?: string,
        service_agree?: string,
        privacy_agree?: string,
        cert_agree?: string,
        marketing_agree?: string
    },
    pet?: {
        id?: number,
        user_id?: number,
        type?: "dog"|"cat"|string,
        name?: string,
        breed?: boolean,
        gender?: string,
        age?: number|null,
        neutralization?:string|null,
        weight?: string,
        main_yn?: string
    },
    tags?: [
        {
            id?:number,
            name?: string,
            health_yn?: string,
            medical_yn?: string
        }
    ]
}

export interface BbsListResponse{
    content:BbsListResponseItem[]
    pageable?:any,
    totalElements?:number,
    totalPages?:number,
}

export interface BbsCategoryResponse{
    content:{
        id?:number //고유 번호
        type?:"notice"|"inquiry"|"faq"|"event" //타입 (notice:공지사항, inquiry:1:1문의, faq:FAQ, event:이벤트)
        name?:number //대분류명
        sort?:number //대분류 정렬 순서
        insert_date?:string // 등록일
        update_date?:string //수정일
        insert_user?:number //등록회원
        update_user?:number //수정회원
    }[]
}

export interface askProps{
    company_name: string
    email: string
    phone?: string
    content: string
}

class BbsService {
    /*
    async list(params:BbsListRequest) {
        return httpClient.post<ApiResponse<BbsListRequest>>("/bbs-service/v1/bbs", {
                page:1,
                limit:10,
                search:"",
                fieldType:"notice",
                animal:"",
                //user_id:0,
                //tag_id:0,
        })
    }
    */
    async list(params:BbsListRequest) {
        // return httpClient.get<ApiResponse<BbsListRequest>>("/bbs-service/v1/bbs", {
        return httpClient.get<ApiResponse<BbsListResponse>>("/bbs-service/v1/bbs?", {
            params: {
                fieldType: params.fieldType,
                page:params.page,
                limit: params.limit,
                search:params.search,
                main_ctgr_id:params.main_ctgr_id
            }
        })
    }
    async categoryList(params:BbsCategoryRequest) {
        // return httpClient.get<ApiResponse<BbsListRequest>>("/bbs-service/v1/bbs", {
        return httpClient.get<ApiResponse<BbsCategoryResponse>>("/bbs-service/v1/bbs/main-category?", {
            params: {
                page:params.page,
                limit: params.limit,
                search:params.search,
            }
        })
    }
    async view({id}:{id: string | number}) {
        // return httpClient.get<ApiResponse<BbsListRequest>>("/bbs-service/v1/bbs", {
        return httpClient.get<ApiResponse<any>>(`/bbs-service/v1/bbs/${id}`, {
            params: {id}
        })
    }
    async ask(params:askProps) {
        // return httpClient.get<ApiResponse<BbsListRequest>>("/bbs-service/v1/bbs", {
        return httpClient.post<ApiResponse<askProps>>(`/push-service/v1/mail/send`, {
            ...params
        })
    }

}

const bbsService = new BbsService()

export default  bbsService

export function useBbsList(params:BbsListRequest){
    const data = useQuery(
        ["bbsList",params],
        async () => {
            const { data } = await bbsService.list({
                ...params,
            })

            return data
        },{

        }
    )

    return data
}

export function useBbsCategoryList(params:BbsListRequest){
    const data = useQuery(
        ["categoryList",params],
        async () => {
            const { data } = await bbsService.categoryList({
                ...params,
            })

            return data
        },{

        }
    )

    return data
}

export function useBbsView(
    //params:string|any
    id?: string | number
):  UseQueryResult<any, unknown> {
    const data = useQuery(
        ["contentView",id],
        async ()=>{
            // await new Promise(resolve => setTimeout(resolve,5000))
            if(id){
                const { data } = await bbsService.view({id : id!})
                return data;
            }
        },{
            suspense : true,
            useErrorBoundary : true
        }
    )

    return data
}

export function useAsk(
    {params}:{
        params:askProps
    }){
    const data = useQuery(
        ["ask",params],
        async ()=>{
            const { data } = await bbsService.ask({
                ...params
            })
            return data
        },
        {
            suspense : true,
            useErrorBoundary : true
        }
    )

    return data
}

