import React from 'react';
import {ModalStyle} from './styled'
import IcX from '@/assets/images/ic-x.png'

const Modal = ({...props}:{
    tit?:string,
    cnt?:any,
    onClose?:()=>void,
    onOk?:()=>void,
    btn?:any
}) =>{
    return (
        <ModalStyle>
            <div className="modal--box">
                <div className="modal--box--tit">
                    <h3>{props.tit}</h3>
                    <button
                        type='button'
                        onClick={props.onClose}
                    >
                        <img src={IcX} alt=""/>
                    </button>
                </div>
                <div className="modal--box--cnt">
                    {props.cnt}
                </div>
                {props.btn&&(
                    <div className="modal--box--footer">
                        {props.btn}
                    </div>
                )}
            </div>
            <div
                className="modal--bg"
                onClick={props.onClose}
            ></div>
        </ModalStyle>
    )
}
export  {Modal};
