import moment from 'moment'
import 'moment/locale/ko';


export const DateFomat = (e:string|null|undefined) =>{

	// if(typeof e ==="string"){
	// 	const changeTime = moment(e).format('YYYYMMDD HH:mm:ss');
	//
	// 	return changeTime
	// }else{
	// 	return ''
	// }

		const changeTime = moment(e).format('YYYY-MM-DD HH:mm:ss');

		return changeTime

}