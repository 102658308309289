import Styled from 'styled-components';


export const ModalStyle = Styled.div`
    width:100%;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    z-index:21;
    display:flex;
    align-items:center;
    justify-content:center;
    .modal{
        &--box{
            width:90%;
            max-width:600px;
            padding:0px 20px 30px;
            border-radius:8px;
            background:#fff;
            box-sizing:border-box;
            max-height:90vh;
            overflow-y:scroll;
            position:relative;
            &--tit{
                display:flex;
                align-items:center;
                justify-content:space-between;
                padding:30px 0 10px;
                position:sticky;
                top:0;
                left:0;
                background:#fff;
                z-index:2;
                h3{
                    font-size:14px;
                    font-weight:900;
                    color:#333;
                }
                button{
                    cursor:pointer;
                }
            }
            &--cnt{
                p{
                    font-size:14px;
                    line-height:1.4;
                    word-break:break-all;
                }
                strong{
                    line-height:2;
                    font-weight:600;
                }
            }
        }
        &--bg{
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.4);
            position:absolute;
            left:0;
            top:0;
            z-index:-1;
            cursor:pointer;
        }
    }
`
