import Styled from 'styled-components';


export const SwiperBannerWrap = Styled.div`
    position:relative;
    .mainBannerMark{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
        z-index:5;
        width:90%;
        // max-width:980px;
        max-width:820px;
        // display:flex;
        // align-items:center;
        // justify-content:flex-start;
        h3{
            font-size:27px;
            font-weight:600;
            color:#fff;
            // width:calc(100% - 40px);
            position:absolute;
            left:40px;
            top:325px;
            // bottom:150px;
            word-break:break-all;
            // line-height:1.3;
            padding-right:40px;
            box-sizing:border-box;
            @media (max-width: 768px){
                left:5%;
                font-size:30px;
                top:initial;
                bottom:5%;
            }
            @media (max-width: 480px){
                font-size:20px;
            }
        }
        img{
            width:100%;
            // height:100%;
        }
    }
    .swiper-wrapper{
        margin:0;
        padding:0;
    }
    .main-swiper{
        .swiper-slide{
            position:relative;
            //height:480px;
            //height:700px;
            height:570px;
            width:100%;
            //background:linear-gradient(to right,#000,#050607);
            @media (max-width: 1024px){
                height:initial;
                aspect-ratio:1/0.64;
            }
            &-img{
                z-index:-1;
                // width:100%;
                width:initial;
                height:100%;
                object-fit:cover;
                margin-left:auto;
                margin-right:auto;
                display:block;
                position:absolute;
                left:50%;
                top:50%;
                transform:translate(-50%,-50%);
            }
        }
    }
`
export const BannerWrap = Styled.div`
    width:100%;
    &.defaultBanner{
        // height:300px;
        height:570px;
        position:relative;
        overflow:hidden;
        // background:linear-gradient(to right,#383a33 50%,#7d7166 50%);
        @media (max-width: 1024px){
            height:initial;
            aspect-ratio:1.5/1;
        }
    }
    &.businessBanner{
        height:480px;
        @media (max-width: 480px){
            height:280px;
        }
    }
    &.companyBanner{
        //background:linear-gradient(to right,#383a33 50%,#7d7166 50%);
    }
    &.contentBanner{
        //background:linear-gradient(to right,#271715 50%,#222a33 50%);
    }
    &.nutrientReportBanner{
        // height:320px;
        @media (max-width: 480px){
            height:200px;
        }
    }
    position:relative;
    .banner{
        &--bg{
            // width:100%;
            height:100%;
            object-fit:cover;
            display:block;
            margin-left:auto;
            margin-right:auto;
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%,-50%);
            .companyBanner--bg{
                position:absolute;
            }
        }
    }
    .defaultBanner--cnt{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
        z-index:5;
        width:90%;
        // max-width:980px;
        max-width:820px;
        &--txt{
            position:absolute;
            left:40px;
            // bottom:35px;
            top:325px;
            h3{
                font-size:27px;
                font-weight:600;
                color:#fff;
                //letter-spacing:-0.5px;
                letter-spacing:-0.2px;
                word-break:break-all;
                line-height:1.3;
            }
            p{
                margin-top:20px;
                font-size:15.4px;
                font-weight:600;
                //color:rgba(255,255,255,0.7);
                color:rgba(255,255,255,1);
                line-height:1.45;
                word-break:break-all;
                letter-spacing:-0.2px;
            }
            @media (max-width: 768px){
                // top:50%;
                top:initial;
                bottom:10px;
                left:5%;
                h3{
                    font-size:18px;
                }
                p{
                    font-size:12px;
                    margin-top:5px;
                    line-height:1.2;
                }
            }
        }
        .markImg{
           width:100%;
        }
    }
    .btnBanner{
        &--txt{
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:center;
            text-align:center;
            h2{
                font-size:36px;
                font-weight:900;
                width:90%;
                max-width:530px;
                color:#fff;
                line-height:1.15;
                word-break:break-all;
                @media (max-width: 480px){
                    width:80%;
                    font-size:20px;
                    font-weight:300;
                    line-height:1.3;
                }
            }
            p{
                margin-top:10px;
                font-size:16px;
                font-weight:600;
                color:#fff;
                line-height:1.5;
                word-break:break-all;
                @media (max-width: 480px){
                    width:80%;
                    font-size:14px;
                    font-weight:300;
                }
            }
        }
        &--qr{
            display:block;
            margin-left:auto;
            margin-right:auto;
            width:100px;
            margin-top:20px;
            @media (max-width: 480px){
                width:80px;
                margin-top:10px;
            }
        }
        &--btn{
            display:flex;
            align-items:center;
            justify-content:center;
            gap:15px;
            margin-top:40px;
            @media (max-width: 480px){
                margin-top:20px;
            }
            a{
                display:flex;
                align-items:center;
                justify-content:center;
                padding:0px 18px;
                height:48px;
                border:2px solid #fff;
                border-radius:8px;
                box-sizing:border-box;
                gap:5px;
                span{
                    font-size:16px;
                    font-weight:600;
                    color:#fff;
                }
            }
        }
    }
    
    .viewBanner{
        &--txt{
            position:absolute;
            left:50%;
            top:0;
            transform:translateX(-50%);
            width:90%;
            height:100%;
            max-width:720px;
            display:flex;
            flex-direction:column;
            align-items:flex-start;
            justify-content:flex-end;
            padding-bottom:30px;
            box-sizing:border-box;
            h2{
                font-size:48px;
                font-weight:900;
                width:100%;
                color:#fff;
                line-height:1.25;
                word-break:break-all;
                text-shadow:0 1px 6px rgba(0,0,0,0.1);
                @media (max-width: 768px){
                    width:90%;
                    font-size:40px;
                    font-weight:300;
                    line-height:1;
                }
            }
            h5{
                font-size:18px;
                font-weight:600;
                color:#fff;
                line-height:1.2;
                margin:10px 0 30px;
                @media (max-width: 768px){
                    font-size:16px;
                    font-weight:300;
                    margin:10px 0 20px;
                }
            }
            p{
                font-size:14px;
                font-weight:600;
                color:#fff;
                line-height:1.2;
                @media (max-width: 768px){
                    font-size:15px;
                    font-weight:300;
                }
            }
        }
        
    }
    &.viewBanner{
        height:380px;
        .banner--bg{
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
`